// File: src/routes/publicRoutes.js

import React, { lazy } from 'react';

// Lazy load all public pages
const Home = lazy(() => import('../pages/Home/Home'));
const SignIn = lazy(() => import('../pages/Auth/SignIn'));
const SignUp = lazy(() => import('../pages/Auth/SignUpWizard.jsx'));
const PricingPage = lazy(() => import('../pages/Pricing/PricingPage'));
const Company = lazy(() => import('../pages/Company.js'));
const ContactSales = lazy(() => import('../pages/ContactSales'));
const PrivacyPolicyPage = lazy(() => import('../pages/Policy/PrivacyPolicyPage'));
const TermsOfServicePage = lazy(() => import('../pages/Policy/TermsOfServicePage'));
const ForgotPassword = lazy(() => import('../pages/Auth/ForgotPassword'));
const HelpAndSupport = lazy(() => import('../pages/HelpSupport/HelpAndSupport'));
const Solutions = lazy(() => import('../pages/Solutions/Solutions.js'));
const Resources = lazy(() => import('../pages/Resources/Resources.js'));
const FeaturesOverviewPage = lazy(() => import('../pages/Features/FeaturesOverviewPage.js'));
const FeatureDetail = lazy(() => import('../pages/Features/FeatureDetail/FeatureDetailPage.js'));
const KnowledgeBase = lazy(() => import('../pages/HelpSupport/KnowledgeBase.js'));
const AddArticle = lazy(() => import('../pages/HelpSupport/AddArticle.js'));
const KnowledgeBaseArticle = lazy(() => import('../pages/HelpSupport/KnowledgeBaseArticle.js'));
const KnowledgeBaseCategory = lazy(() => import('../pages/HelpSupport/KnowledgeBaseCategory.js'));
const PublicPortal = lazy(() => import('../pages/Service/PublicPortal.jsx'));
const QuoteShareView = lazy(() => import('../pages/Commerce/Quotes/QuoteShare/QuoteShareView.jsx'));
const InvoiceShareView = lazy(() => import('../pages/Commerce/Invoices/InvoiceShare/InvoiceShareView.jsx'));
const SubscriptionShareView = lazy(() => import('../pages/Commerce/Subscriptions/SubscriptionShare/SubscriptionPublicView.jsx'));
const SubscriptionSignUpView = lazy(() => import('../pages/Commerce/Subscriptions/SubscriptionDetails/SubscriptionSignups.jsx'));
const SponsorDownloads = lazy(() => import('../pages/Resources/Downloads/SponsorDownloads.js'));
const PaymentRequestPublicPage = lazy(() => import('../pages/Commerce/PaymentLinks/PaymentShare/PaymentRequestPublicPage.jsx'));
const OnboardingPage = lazy(() => import('../pages/Onboarding/OnboardingPage.js'));
const Settings = lazy(() => import('../pages/Settings/Settings.js'));
const SolutionDetailPage = lazy(() => import('../pages/Solutions/SolutionDetailPage')); // <-- Add this
const Courses = lazy(() => import('../pages/Courses/Courses.js')); // <-- Add this
const Blog = lazy(() => import('/Users/willradley/orthinas-crm/src/pages/Blog/Blog.js')); // <-- Add this
const PartnerProgram = lazy(() => import('../pages/PartnerProgram/PartnerProgram.js')); // <-- Add this
const CourseDetail = lazy(() => import('../pages/Courses/CourseDetail'));
const BlogDetail = lazy(() => import('../pages/Blog/BlogDetail.jsx'));

/**
 * Public routes (no authentication required)
 * Each route object has:
 *  - path
 *  - element
 */
export const publicRoutes = [
  { path: '/onboarding', element: <OnboardingPage /> },
  { path: '/settings', element: <Settings /> },
  { path: '/solutions/:slug', element: <SolutionDetailPage /> }, // <-- NEW route
  { path: '/courses', element: <Courses /> },       // the course listing
  { path: '/courses/:courseId', element: <CourseDetail /> }, // detail page

  { path: '/blog', element: <Blog /> },
  { path: '/partnerprogram', element: <PartnerProgram /> },
  { path: '/blog/:postId', element: <BlogDetail /> }, // <-- new route for individual posts

  { path: '/', element: <Home /> },
  { path: '/signin', element: <SignIn /> },
  { path: '/signup', element: <SignUp /> },
  { path: '/pricing', element: <PricingPage /> },
  { path: '/company', element: <Company /> },
  { path: '/contact-sales', element: <ContactSales /> },
  { path: '/policy/privacy', element: <PrivacyPolicyPage /> },
  { path: '/policy/terms', element: <TermsOfServicePage /> },
  { path: '/forgot-password', element: <ForgotPassword /> },
  { path: '/help-and-support', element: <HelpAndSupport /> },
  { path: '/solutions', element: <Solutions /> },
  { path: '/resources', element: <Resources /> },
  { path: '/features', element: <FeaturesOverviewPage /> },
  { path: '/features/:id', element: <FeatureDetail /> },
  { path: '/knowledgebase', element: <KnowledgeBase /> },
  { path: '/knowledge-base/create', element: <AddArticle /> },
  { path: '/knowledge-base/article/:articleId', element: <KnowledgeBaseArticle /> },
  { path: '/knowledge-base/category/:categoryName', element: <KnowledgeBaseCategory /> },
  { path: '/portal/:portalId', element: <PublicPortal /> },
  { path: '/quote-share/:shareId', element: <QuoteShareView /> },
  { path: '/invoice-share/:shareId', element: <InvoiceShareView /> },
  { path: '/subscription-share/:shareId', element: <SubscriptionShareView /> },
  { path: '/subscriptions/:subscriptionId/signups', element: <SubscriptionSignUpView /> },
  { path: '/downloads', element: <SponsorDownloads /> },

  // Public Payment Route
  { path: '/pay/:shareId', element: <PaymentRequestPublicPage /> },
];
