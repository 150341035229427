// src/components/Footer.js

import React, { useState, useCallback, useMemo } from 'react';
import {
  Box,
  Typography,
  Link as MuiLink,
  Grid,
  TextField,
  IconButton,
  Button,
  Container,
  Divider,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Zoom,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import {
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  LinkedIn as LinkedInIcon,
  Twitter as TwitterIcon,
  YouTube as YouTubeIcon,
  Public as PublicIcon,
  AlternateEmail as AlternateEmailIcon,
  MusicNote as MusicNoteIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material';

import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { getApp } from 'firebase/app';

/** ------------------ Styled Components ------------------ **/

const FooterWrapper = styled('footer')(({ theme }) => ({
  background: `linear-gradient(120deg, ${theme.palette.grey[900]} 0%, ${theme.palette.grey[800]} 100%)`,
  color: theme.palette.grey[100],
  marginTop: theme.spacing(12),
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(6),
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: -50,
    left: 0,
    width: '100%',
    height: '50px',
    background: `url('/images/wave.svg') no-repeat center top`,
    backgroundSize: 'cover',
    pointerEvents: 'none',
    opacity: 0.4,
  },
}));

const FooterLink = styled(MuiLink)(({ theme }) => ({
  color: theme.palette.grey[300],
  textDecoration: 'none',
  display: 'block',
  marginBottom: theme.spacing(1),
  fontSize: '0.95rem',
  '&:hover': {
    textDecoration: 'underline',
    color: theme.palette.primary.light,
  },
}));

const SocialIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.grey[300],
  marginRight: theme.spacing(1.5),
  transition: 'color 0.2s ease',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

// Reusable styles for inputs
const textFieldStyles = {
  input: {
    color: '#fff',
    '::placeholder': { color: 'rgba(255,255,255,0.7)' },
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(255,255,255,0.5)',
    },
    '&:hover fieldset': {
      borderColor: '#fff',
    },
  },
};

// Reusable styles for buttons
const outlinedButtonStyles = {
  textTransform: 'none',
  fontWeight: 'bold',
  borderRadius: 20,
  borderColor: 'rgba(255,255,255,0.5)',
  color: '#fff',
  '&:hover': {
    borderColor: '#fff',
  },
};

/** 
 * Renders social media icons with corresponding links.
 */
const SocialLinks = () => {
  const socialItems = useMemo(
    () => [
      { href: 'https://www.facebook.com/orthinas/', icon: <FacebookIcon />, label: 'Facebook' },
      { href: 'https://www.instagram.com/orthinas/', icon: <InstagramIcon />, label: 'Instagram' },
      { href: 'https://www.threads.net/@orthinas', icon: <AlternateEmailIcon />, label: 'Threads' },
      { href: 'https://x.com/orthinas', icon: <TwitterIcon />, label: 'X (Twitter)' },
      { href: 'https://www.tiktok.com/@orthinas', icon: <MusicNoteIcon />, label: 'TikTok' },
      { href: 'https://www.youtube.com/channel/UCTMdfrrWF2neRxVNV7yL7Kg', icon: <YouTubeIcon />, label: 'YouTube' },
      { href: 'https://www.linkedin.com/company/105839041', icon: <LinkedInIcon />, label: 'LinkedIn' },
      { href: 'https://bsky.app/profile/orthinas.bsky.social', icon: <PublicIcon />, label: 'BlueSky' },
    ],
    []
  );

  return (
    <Box>
      {socialItems.map(({ href, icon, label }) => (
        <SocialIconButton
          key={label}
          component="a"
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={label}
        >
          {icon}
        </SocialIconButton>
      ))}
    </Box>
  );
};

/** 
 * NewsletterForm Component
 * Renders the newsletter subscription form.
 */
const NewsletterForm = ({ onCancel, onSubmit, isSubmitting, error }) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  const handleSubscribe = useCallback(() => {
    onSubmit({ email, name });
  }, [email, name, onSubmit]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 3 }}>
      <TextField
        variant="outlined"
        size="small"
        placeholder="Enter your name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        sx={textFieldStyles}
      />

      <TextField
        variant="outlined"
        size="small"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        sx={textFieldStyles}
      />

      {error && (
        <Typography variant="body2" sx={{ color: 'red', mb: 1 }}>
          {error}
        </Typography>
      )}

      <Box sx={{ display: 'flex', gap: 1 }}>
        <Button
          variant="outlined"
          size="small"
          sx={outlinedButtonStyles}
          onClick={handleSubscribe}
          disabled={isSubmitting}
        >
          {isSubmitting ? <CircularProgress size={16} sx={{ color: '#fff' }} /> : 'Submit'}
        </Button>

        <Button
          variant="text"
          size="small"
          color="inherit"
          onClick={onCancel}
          sx={{
            textTransform: 'none',
            color: '#fff',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

/**
 * Footer Component
 * Displays the footer with company info, links, legal pages, and newsletter subscription.
 */
function Footer() {
  // Current year
  const currentYear = new Date().getFullYear();

  // Newsletter form states
  const [showForm, setShowForm] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [error, setError] = useState('');

  const db = getFirestore(getApp());

  const handleToggleForm = useCallback(() => {
    setShowForm((prev) => !prev);
    setError('');
    setIsSubscribed(false);
  }, []);

  const handleSubscribe = useCallback(
    async ({ email, name }) => {
      if (!email || !name) {
        setError('Please enter both your name and email.');
        return;
      }

      setIsSubmitting(true);
      setError('');

      try {
        await addDoc(collection(db, 'registrations'), {
          emailAddress: email,
          firstName: name,
        });

        // Reset state after successful subscription
        setIsSubscribed(true);
        setShowForm(false);
      } catch (err) {
        console.error(err);
        setError('An error occurred while subscribing. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    },
    [db]
  );

  // Scroll to top button
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [showTopButton, setShowTopButton] = useState(false);

  const handleScroll = useCallback(() => {
    if (window.scrollY > 400) {
      setShowTopButton(true);
    } else {
      setShowTopButton(false);
    }
  }, []);

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <FooterWrapper>
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="space-between">
          {/* Company Info */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: 'white' }}>
              RCF Orthinas
            </Typography>
            {/* Updated copy highlighting the brand's personality and core feature set */}
            <Typography variant="body2" sx={{ mb: 2, opacity: 0.8, lineHeight: 1.6 }}>
              Empower your business with AI-driven, intuitive CRM solutions designed to
              streamline customer interactions and accelerate growth. RCF Orthinas adapts
              to your workflow—so you can focus on building meaningful relationships.
            </Typography>

            <Typography variant="body2" sx={{ mb: 1, opacity: 0.7 }}>
              Orthinas is a brand by RCF.
            </Typography>
            <Typography variant="body2" sx={{ opacity: 0.7 }}>
              © {currentYear} RCF Orthinas. All rights reserved.
            </Typography>
          </Grid>

          {/* Why RCF Orthinas? (Unique Value Proposition Section) */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: 'white' }}>
              Why RCF Orthinas?
            </Typography>
            <Typography variant="body2" sx={{ mb: 2, opacity: 0.8, lineHeight: 1.6 }}>
              Unlike many traditional CRMs, RCF Orthinas is built from the ground up to
              leverage cutting-edge AI, offering a truly bespoke experience for every team.
              Our focus is on user-centric design, actionable insights, and comprehensive
              customization options—all to help you close more deals and nurture stronger
              customer relationships.
            </Typography>
           
          </Grid>

          {/* Quick Links */}
          <Grid item xs={12} sm={6} md={2}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: 'white' }}>
              Quick Links
            </Typography>
           
            <FooterLink component={RouterLink} to="/contact-sales">
              Contact Sales
            </FooterLink>
            <FooterLink component={RouterLink} to="/pricing">
              Pricing
            </FooterLink>
            <FooterLink component={RouterLink} to="/help-and-support">
              Help &amp; Support
            </FooterLink>
            <FooterLink component={RouterLink} to="/downloads">
          Sponsor Downloads
           </FooterLink>
          </Grid>

          {/* Legal and Account */}
          <Grid item xs={12} sm={6} md={2}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: 'white' }}>
              Legal
            </Typography>
            <FooterLink component={RouterLink} to="/policy/privacy">
              Privacy Policy
            </FooterLink>
            <FooterLink component={RouterLink} to="/policy/terms">
              Terms of Service
            </FooterLink>

            <Divider sx={{ my: 2, backgroundColor: 'rgba(255,255,255,0.1)' }} />

            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: 'white' }}>
              Account
            </Typography>
            <FooterLink component={RouterLink} to="/login">
              Login
            </FooterLink>
            <FooterLink component={RouterLink} to="/signup">
              Sign Up
            </FooterLink>
          </Grid>

          {/* Newsletter & Social */}
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: 'white' }}>
              Stay in the Loop
            </Typography>
            {!isSubscribed && !showForm && (
              <Box sx={{ mb: 3 }}>
                <Typography variant="body2" sx={{ mb: 2, opacity: 0.8, lineHeight: 1.6 }}>
                  Get the latest on Orthinas CRM—feature releases, productivity tips, and
                  special offers—right in your inbox.
                </Typography>
                <Button variant="outlined" size="small" sx={outlinedButtonStyles} onClick={handleToggleForm}>
                  Subscribe
                </Button>
              </Box>
            )}
            {showForm && !isSubscribed && (
              <NewsletterForm
                onCancel={handleToggleForm}
                onSubmit={handleSubscribe}
                isSubmitting={isSubmitting}
                error={error}
              />
            )}
            {isSubscribed && (
              <Box sx={{ mb: 3 }}>
                <Typography variant="body2" sx={{ color: 'lightgreen', mb: 1 }}>
                  Thank you for subscribing! Check your inbox for more details.
                </Typography>
              </Box>
            )}

            {/* Social Icons */}
            <SocialLinks />
          </Grid>
        </Grid>

        <Divider sx={{ mt: 8, mb: 4, backgroundColor: 'rgba(255,255,255,0.1)' }} />

        <Typography variant="body2" color="grey.400" textAlign="center" sx={{ opacity: 0.8 }}>
          Orthinas is proudly crafted by RCF in the UK.
        </Typography>
      </Container>

    </FooterWrapper>
  );
}

export default Footer;
