// src/pages/LockedPage.jsx

import React from 'react';
import { Box, Typography, Button, Stack } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { Lock as LockIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

// A wave-based background container for the locked page
const LockedPageContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  minHeight: '75vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  padding: theme.spacing(4),
  // Subtle gradient behind content
  background: `linear-gradient(135deg, ${alpha(theme.palette.primary.light, 0.1)} 0%, ${alpha(
    theme.palette.secondary.light,
    0.05
  )} 100%)`,
  // Waves at top & bottom
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '-80px',
    left: 0,
    width: '100%',
    height: '80px',
    background: `url("data:image/svg+xml,%3Csvg fill='%23ffffff' viewBox='0 0 1440 320' preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0,32L60,58.7C120,85,240,139,360,160C480,181,600,171,720,165.3C840,160,960,160,1080,165.3C1200,171,1320,181,1380,186.7L1440,192L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z'%3E%3C/path%3E%3C/svg%3E")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    opacity: 0.15,
    zIndex: 1,
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: '-80px',
    left: 0,
    width: '100%',
    height: '80px',
    background: `url("data:image/svg+xml,%3Csvg fill='%23ffffff' viewBox='0 0 1440 320' preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0,288L40,245.3C80,203,160,117,240,96C320,75,400,117,480,112C560,107,640,53,720,53.3C800,53,880,107,960,138.7C1040,171,1120,181,1200,192C1280,203,1360,213,1400,218.7L1440,224L1440,320L1400,320C1360,320,1280,320,1200,320C1040,320,960,320,880,320C800,320,720,320,640,320C560,320,480,320,400,320C320,320,240,320,160,320C80,320,40,320,0,320Z'%3E%3C/path%3E%3C/svg%3E")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    opacity: 0.15,
    zIndex: 1,
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  fontSize: 72,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(3),
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

export default function LockedPage() {
  const navigate = useNavigate();

  return (
    <LockedPageContainer>
      <Box
        sx={{
          position: 'relative',
          zIndex: 2,
          maxWidth: 600,
          textAlign: 'center',
          width: '100%',
        }}
      >
        <IconWrapper>
          <LockIcon sx={{ fontSize: 'inherit' }} />
        </IconWrapper>

        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
          Oops! You don’t have access to this page
        </Typography>

        <Typography variant="body1" sx={{ mb: 4, color: 'text.secondary' }}>
          It looks like your current plan doesn’t include this feature or page.
          Upgrade now to unlock all the tools and benefits, or explore other pages.
        </Typography>

        <Stack direction="row" spacing={2} justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/pricing')}
            sx={{ textTransform: 'none', borderRadius: 20, px: 3 }}
          >
            See Upgrade Options
          </Button>

          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate('/dashboard')}
            sx={{ textTransform: 'none', borderRadius: 20, px: 3 }}
          >
            Back to Dashboard
          </Button>
        </Stack>
      </Box>
    </LockedPageContainer>
  );
}
