// src/components/Navbar/MobileLoggedOutView.jsx

import React, { useState } from 'react';
import {
  Tooltip,
  IconButton,
  Stack,
  Menu,
  MenuItem,
  Divider,
} from '@mui/material';
import {
  HelpOutline as HelpOutlineIcon,
  Menu as MenuIcon,
} from '@mui/icons-material';

/**
 * IMPORTANT: Import Link from 'react-router-dom', not from '@mui/material'
 */
import { Link as RouterLink } from 'react-router-dom';

import BigProductMegaMenu from '../Components/Navbar/BigProductMegaMenu';
import BigSolutionsMegaMenu from '../Components/Navbar/BigSolutionsMegaMenu';
import BigResourcesMegaMenu from '../Components/Navbar/BigResourcesMegaMenu';

function IconTooltipButton({ title, label, IconComponent, onClick, sx, anchor }) {
  return (
    <Tooltip title={title} arrow>
      <IconButton
        onClick={onClick}
        sx={sx}
        aria-label={label}
        aria-haspopup="true"
        aria-expanded={Boolean(anchor)}
      >
        <IconComponent />
      </IconButton>
    </Tooltip>
  );
}

export default function MobileLoggedOutView({
  iconButtonStyles,
  handleHelpMenuOpen,
  helpAnchorEl,
  mobileAnchorEl,
  handleMobileMenuOpen,
}) {
  // 1) Minimal top bar icons: "Help" & "Hamburger"
  const topActions = [
    {
      title: 'Help & Support',
      label: 'Help and Support',
      IconComponent: HelpOutlineIcon,
      onClick: handleHelpMenuOpen,
      anchor: helpAnchorEl,
    },
    {
      title: 'Open mobile menu',
      label: 'Open mobile menu',
      IconComponent: MenuIcon,
      onClick: handleMobileMenuOpen,
      anchor: mobileAnchorEl,
    },
  ];

  // 2) Local anchor state for hamburger items
  const [hamburgerAnchor, setHamburgerAnchor] = useState(null);

  // 3) Anchor states for the Product, Solutions, Resources mega menus
  const [productAnchorEl, setProductAnchorEl] = useState(null);
  const [solutionsAnchorEl, setSolutionsAnchorEl] = useState(null);
  const [resourcesAnchorEl, setResourcesAnchorEl] = useState(null);

  const openProductMenu = Boolean(productAnchorEl);
  const openSolutionsMenu = Boolean(solutionsAnchorEl);
  const openResourcesMenu = Boolean(resourcesAnchorEl);

  // Handlers for each mega menu
  const handleProductMenuOpen = (e) => setProductAnchorEl(e.currentTarget);
  const handleProductMenuClose = () => setProductAnchorEl(null);

  const handleSolutionsMenuOpen = (e) => setSolutionsAnchorEl(e.currentTarget);
  const handleSolutionsMenuClose = () => setSolutionsAnchorEl(null);

  const handleResourcesMenuOpen = (e) => setResourcesAnchorEl(e.currentTarget);
  const handleResourcesMenuClose = () => setResourcesAnchorEl(null);

  // Hamburger menu: open/close
  const handleHamburgerClick = (event) => setHamburgerAnchor(event.currentTarget);
  const handleHamburgerClose = () => setHamburgerAnchor(null);

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {/* Render the top-level icons (Help, Hamburger) */}
      {topActions.map((action) => (
        <IconTooltipButton
          key={action.title}
          title={action.title}
          label={action.label}
          IconComponent={action.IconComponent}
          onClick={
            action.label === 'Open mobile menu'
              ? handleHamburgerClick // override
              : action.onClick
          }
          sx={iconButtonStyles}
          anchor={action.anchor}
        />
      ))}

      {/* The "Help" menu, if any, is presumably elsewhere... */}

      {/* The hamburger menu with Product, Solutions, Resources, Pricing, Sign In, Sign Up */}
      <Menu
        anchorEl={hamburgerAnchor}
        open={Boolean(hamburgerAnchor)}
        onClose={handleHamburgerClose}
        PaperProps={{
          sx: {
            mt: 1.5,
            minWidth: 200,
          },
        }}
      >
        {/* 1) Product -> BigProductMegaMenu */}
        <MenuItem
          onClick={(e) => {
            handleHamburgerClose();
            handleProductMenuOpen(e);
          }}
        >
          Product
        </MenuItem>

        {/* 2) Solutions -> BigSolutionsMegaMenu */}
        <MenuItem
          onClick={(e) => {
            handleHamburgerClose();
            handleSolutionsMenuOpen(e);
          }}
        >
          Solutions
        </MenuItem>

        {/* 3) Resources -> BigResourcesMegaMenu */}
        <MenuItem
          onClick={(e) => {
            handleHamburgerClose();
            handleResourcesMenuOpen(e);
          }}
        >
          Resources
        </MenuItem>


        {/* 4) Pricing -> normal route */}
        <MenuItem
          component={RouterLink}  // Use React Router's Link
          to="/pricing"
          onClick={handleHamburgerClose}
        >
          Pricing
        </MenuItem>
        <Divider sx={{ my: 1 }} />

        {/* 5) Sign In -> normal route */}
        <MenuItem
          component={RouterLink}
          to="/signin"
          onClick={handleHamburgerClose}
        >
          Sign In
        </MenuItem>

        {/* 6) Sign Up -> normal route */}
        <MenuItem
          component={RouterLink}
          to="/signup"
          onClick={handleHamburgerClose}
        >
          Sign Up
        </MenuItem>
      </Menu>

      {/* Mega Menus triggered after we close hamburger */}
      <BigProductMegaMenu
        anchorEl={productAnchorEl}
        open={openProductMenu}
        onClose={handleProductMenuClose}
      />

      <BigSolutionsMegaMenu
        anchorEl={solutionsAnchorEl}
        open={openSolutionsMenu}
        onClose={handleSolutionsMenuClose}
      />

      <BigResourcesMegaMenu
        anchorEl={resourcesAnchorEl}
        open={openResourcesMenu}
        onClose={handleResourcesMenuClose}
      />
    </Stack>
  );
}
