// src/components/Navbar/HelpMenu.jsx

import React from 'react';
import {
  Menu,
  Grow,
  Box,
  Typography,
  Divider,
  MenuItem,
  Link as MuiLink,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
  Tooltip,
} from '@mui/material';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import ArticleIcon from '@mui/icons-material/Article';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import ChatIcon from '@mui/icons-material/Chat'; // NEW: Example icon for an AI Chat feature

/**
 * HelpMenu Component
 *
 * Enhanced help & support menu that:
 *  1. Showcases RCF Orthinas's AI-driven assistance and unique value.
 *  2. Displays recommended articles or resources based on the current route.
 *  3. Offers quick links to knowledge base, support, team collaboration, and more.
 *
 * @param {object} props
 * @param {HTMLElement} props.anchorEl - The HTML element used to set the position of the menu.
 * @param {boolean} props.open - Whether the menu is open.
 * @param {function} props.onClose - Callback to close the menu.
 * @param {object} props.location - React Router location object (to pass current pathname).
 * @param {function} props.getRecommendedArticle - Function returning article or link for the current route.
 */
function HelpMenu({
  anchorEl,
  open,
  onClose,
  location,
  getRecommendedArticle,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Example: If your recommended article is a link, wrap it in a <MuiLink>
  const recommendedContent = getRecommendedArticle(location.pathname);
  const isLink = recommendedContent?.startsWith('http');

  // Optional placeholder for an AI help feature: 
  // You might integrate an actual chat interface or direct link here.
  const handleAIChatSupport = () => {
    alert("We're not quite ready with Orthinas Assist. Please check back shortly.");
    onClose();
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      TransitionComponent={Grow}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      sx={{
        '& .MuiMenu-paper': {
          width: isMobile ? '100%' : 300, // slightly wider for a better layout
          p: 1,
          borderRadius: 2,
          overflow: 'hidden',
        },
      }}
      aria-label="Help menu"
    >
      {/* Top bar with a close button */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: 2,
          py: 1,
        }}
      >
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          Orthinas Assist
        </Typography>
        <Tooltip title="Close">
          <IconButton size="small" onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>

      <Divider />

      {/* Brief explanation of Orthinas's unique value in user support */}
      <Box sx={{ px: 2, py: 1 }}>
        <Typography variant="body2" color="text.secondary">
          Orthinas goes beyond standard help desks. Our tools and proactive
          team support ensure you always have the right answers at the right time.
        </Typography>
      </Box>

      {/* AI Chat Support (Placeholder) */}
      <MenuItem onClick={handleAIChatSupport}>
        <ChatIcon fontSize="small" sx={{ mr: 1 }} />
        Ask Orthinas AI
      </MenuItem>

      <Divider />

      {/* Knowledge Base & Contact Support */}
      <Box sx={{ px: 2, py: 1 }}>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
          Explore our resources and get expert assistance:
        </Typography>
      </Box>
      <MenuItem
        component={MuiLink}
        href="/portal/YDG0CrVq962HOlUWqNhd"
        underline="none"
        onClick={onClose}
      >
        <ArticleIcon fontSize="small" sx={{ mr: 1 }} />
        Knowledge Base
      </MenuItem>
      <MenuItem
        component={MuiLink}
        href="/help-and-support"
        underline="none"
        onClick={onClose}
      >
        <SupportAgentIcon fontSize="small" sx={{ mr: 1 }} />
        Contact Support
      </MenuItem>

      <Divider sx={{ my: 1 }} />

      {/* Recommended Article or Resource */}
      <Box sx={{ px: 2, py: 1 }}>
        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
          Recommended Resource
        </Typography>
        {isLink ? (
          <MuiLink href={recommendedContent} variant="body2" target="_blank" rel="noopener">
            {recommendedContent}
          </MuiLink>
        ) : (
          <Typography variant="body2" color="text.secondary">
            {recommendedContent || 'No recommendations at this time.'}
          </Typography>
        )}
      </Box>

      <Divider sx={{ my: 1 }} />

      {/* Team Collaboration & Growth */}
      <Stack sx={{ px: 2, py: 1 }} spacing={1}>
        <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
          Team Collaboration &amp; Growth
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Invite colleagues to Orthinas or get expert coaching to grow your business:
        </Typography>
      </Stack>
      <MenuItem component={Link} to="/invite-team" onClick={onClose}>
        <GroupAddIcon fontSize="small" sx={{ mr: 1 }} />
        Invite a Team Member
      </MenuItem>
      <MenuItem component={Link} to="/connect-with-expert" onClick={onClose}>
        <EmojiPeopleIcon fontSize="small" sx={{ mr: 1 }} />
        Success Coach
      </MenuItem>
    </Menu>
  );
}

export default HelpMenu;
