import React, { createContext, useState, useEffect } from 'react';
import { db, auth } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { collection, query, where, getDocs } from 'firebase/firestore';

export const SubscriptionContext = createContext();

const TRIAL_PERIOD_DAYS = 30;

/** 
 * Simple helper to return how many days have passed since a date.
 */
function getDaysSince(date) {
  if (!date) return Infinity;
  const now = new Date();
  return Math.floor((now - new Date(date)) / (1000 * 60 * 60 * 24));
}

export function SubscriptionProvider({ children }) {
  const [user, loadingUser] = useAuthState(auth);

  const [subscriptions, setSubscriptions] = useState([]);
  const [planLoading, setPlanLoading] = useState(true);
  const [trialDaysRemaining, setTrialDaysRemaining] = useState(null);

  // This is what the NavBar will display as the plan label
  const [currentPlan, setCurrentPlan] = useState(null);

  useEffect(() => {
    if (loadingUser) return;
    if (!user) {
      // User signed out => no subs, no plan
      setSubscriptions([]);
      setTrialDaysRemaining(null);
      setCurrentPlan(null);
      setPlanLoading(false);
      return;
    }

    const fetchSubscriptions = async () => {
      try {
        const subRef = collection(db, 'customers', user.uid, 'subscriptions');
        const q = query(subRef, where('status', 'in', ['trialing', 'active']));
        const snap = await getDocs(q);

        if (!snap.empty) {
          // Build array of subscription objects
          const userSubs = snap.docs.map((docSnap) => {
            const data = docSnap.data();
            let modules = [];
            let tier = null;
            let totalSeats = 0;

            // 'items' is an array of line items with .price.metadata or .plan.metadata
            const items = Array.isArray(data.items) ? data.items : [];

            items.forEach((item) => {
              const meta = item?.price?.metadata || item?.plan?.metadata || {};

              // modules might be "Sales,Marketing"
              const modsString = meta.modules || meta.stripe_metadata_modules;
              if (modsString) {
                const splitted = modsString
                  .split(',')
                  .map((m) => m.trim())
                  .filter(Boolean);
                modules.push(...splitted);
              }

              // tier might be "Starter" or "Pro"
              if (!tier) {
                tier = meta.tier || meta.stripe_metadata_tier || null;
              }

              // quantity => seatCount
              if (typeof item.quantity === 'number') {
                totalSeats += item.quantity;
              }
            });

            // Remove duplicates (if user purchased multiple items with same module)
            modules = [...new Set(modules)];

            return {
              id: docSnap.id,
              ...data,
              modules,
              tier,
              seatCount: totalSeats,
            };
          });

          setSubscriptions(userSubs);
          setTrialDaysRemaining(null);

          // Pick the first active/trialing subscription
          const activeSub = userSubs.find(
            (s) => s.status === 'active' || s.status === 'trialing'
          );

          if (activeSub) {
            // If we have a tier, use that
            let foundPlan = activeSub.tier;
            // Otherwise fallback to the first module if available
            if (!foundPlan && activeSub.modules.length > 0) {
              // e.g. if modules = ["Sales"], we'll show "Sales"
              foundPlan = activeSub.modules[0];
            }
            setCurrentPlan(foundPlan ?? null);
          } else {
            setCurrentPlan(null);
          }
        } else {
          // No active or trialing subscriptions => see if user is still in trial
          const creationTime = user.metadata?.creationTime;
          if (creationTime) {
            const daysSince = getDaysSince(creationTime);
            if (daysSince < TRIAL_PERIOD_DAYS) {
              setTrialDaysRemaining(TRIAL_PERIOD_DAYS - daysSince);
            } else {
              setTrialDaysRemaining(0);
            }
          } else {
            setTrialDaysRemaining(0);
          }
          setSubscriptions([]);
          setCurrentPlan(null);
        }
      } catch (err) {
        console.error('Error fetching subscriptions:', err);
        setSubscriptions([]);
        setCurrentPlan(null);
      } finally {
        setPlanLoading(false);
      }
    };

    fetchSubscriptions();
  }, [user, loadingUser]);

  return (
    <SubscriptionContext.Provider
      value={{
        subscriptions,
        planLoading,
        trialDaysRemaining,
        currentPlan,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
}
