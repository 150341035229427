// useMessages.js

import { useEffect, useState, useRef, useCallback } from 'react';
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  addDoc,
  serverTimestamp,
  where,
  setDoc,
  doc,
} from 'firebase/firestore';

/**
 * Handles:
 *  - Subscribing to the "messages" collection in Firestore
 *  - Sending new messages
 *  - Tracking errors/loading
 *  - Keeping track of whether conversation is closed
 */
export default function useMessages({
  db,
  conversationId,
  currentUser,
  userProfile,
  guestEmail,
  guestName,
}) {
  const [messages, setMessages] = useState([]);
  const [messagesLoading, setMessagesLoading] = useState(true);
  const [messagesError, setMessagesError] = useState(null);
  const [isConversationClosed, setIsConversationClosed] = useState(false);

  // For auto-scroll to bottom
  const messagesEndRef = useRef(null);

  // Subscribe to messages if we have a conversationId
  useEffect(() => {
    if (!conversationId) {
      setMessages([]);
      setMessagesLoading(false);
      return;
    }

    setMessagesLoading(true);

    const messagesRef = collection(db, 'messages');
    const q = query(
      messagesRef,
      where('conversationId', '==', conversationId),
      orderBy('createdAt', 'asc')
    );

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const loaded = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setMessages(loaded);
        setMessagesLoading(false);
      },
      (error) => {
        console.error('Error fetching messages:', error);
        setMessagesError('Failed to load messages.');
        setMessagesLoading(false);
      }
    );

    return () => unsubscribe();
  }, [db, conversationId]);

  // Check if conversation is closed & auto-scroll
  useEffect(() => {
    // Auto-scroll
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    // Check if conversation is closed
    if (messages.length > 0) {
      const allClosed = messages.every((msg) => msg.closed === true);
      setIsConversationClosed(allClosed);
    } else {
      setIsConversationClosed(false);
    }
  }, [messages]);

  const sendMessage = useCallback(
    async (textToSend) => {
      if (!conversationId) return;
      if (!textToSend.trim()) return;

      // If it's closed, block
      if (isConversationClosed) {
        setMessagesError('This chat has been closed by our support team.');
        return;
      }

      // If guest but no email => block
      if (!currentUser?.uid && !guestEmail) {
        setMessagesError('Please provide your email before sending a message.');
        return;
      }

      // Prepare sender info
      const senderId = conversationId;
      let senderName;
      let senderRole;
      let senderEmail = null;

      if (currentUser?.uid) {
        senderRole = userProfile?.role || 'user';
        senderName = userProfile?.displayName || currentUser.email || 'User';
      } else {
        senderRole = 'guest';
        senderName = guestName || 'Guest';
        senderEmail = guestEmail;
      }

      try {
        // Send new message
        await addDoc(collection(db, 'messages'), {
          text: textToSend.trim(),
          senderId,
          senderName,
          senderRole,
          senderEmail,
          conversationId,
          createdAt: serverTimestamp(),
          closed: false,
        });

        // If guest & first message => auto-reply
        if (!currentUser?.uid) {
          const autoReplySent = localStorage.getItem('autoReplySent');
          if (!autoReplySent) {
            const autoReplyText = guestEmail
              ? `Thanks ${guestName || ''}! We’ll reply soon at ${guestEmail}.`
              : 'We’ve received your message. We’ll get back to you shortly!';

            await addDoc(collection(db, 'messages'), {
              text: autoReplyText,
              senderId: 'autoBot',
              senderName: 'System',
              senderRole: 'system',
              conversationId,
              createdAt: serverTimestamp(),
              closed: false,
            });
            localStorage.setItem('autoReplySent', 'true');
          }
        }
        setMessagesError(null);
      } catch (err) {
        console.error('Error sending message:', err);
        setMessagesError('Failed to send message. Please try again.');
      }
    },
    [
      db,
      currentUser,
      userProfile,
      guestEmail,
      guestName,
      conversationId,
      isConversationClosed,
    ]
  );

  return {
    messages,
    messagesLoading,
    messagesError,
    setMessagesError,
    isConversationClosed,
    messagesEndRef,
    sendMessage,
  };
}
