// File: src/routes/protectedRoutes.js

import React, { lazy } from 'react';

// Lazy load pages that only require login (no specific module)
const Dashboard = lazy(() => import('../pages/Dashboard/Dashboard'));
const ArticlesDocumentation = lazy(() => import('../pages/ArticlesDocumentation.js'));
const ContactDetails = lazy(() => import('../pages/Sales/Contacts/ContactDetails/ContactDetails.js'));
const DealsList = lazy(() => import('../pages/Sales/Deals/DealsList/DealsList.js'));
const DealDetails = lazy(() => import('../pages/Sales/Deals/DealDetails/DealDetails.js'));
const Reports = lazy(() => import('../pages/Reports/Reports'));
const Contacts = lazy(() => import('../pages/Sales/Contacts/ContactsList/ContactsList.js'));
const CreateWhatsNewPage = lazy(() => import('../pages/Dashboard/components/CreateWhatsNewPage.js'));
const AdminChatPage = lazy(() => import('../pages/AdminChatPage.jsx'));
const CreateBlogPost = lazy(() => import('../pages/Blog/CreateBlogPost'));
/**
 * Protected routes (must be logged in), but do NOT require any specific module.
 */
export const protectedRoutes = [
  { path: '/admin-chats', element: <AdminChatPage /> },
  { path: '/blog/create', element: <CreateBlogPost /> },
  { path: '/dashboard', element: <Dashboard /> },
  { path: '/articles', element: <ArticlesDocumentation /> },
  { path: '/sales/contacts/:id', element: <ContactDetails /> },
  { path: '/sales/contacts', element: <Contacts /> },
    { path: '/sales/deals', element: <DealsList /> },  // If you want "Sales" module for this, move it to moduleProtectedRoutes
  { path: '/sales/deal/:id', element: <DealDetails /> },
  { path: '/reports', element: <Reports /> },
  { path: '/dashboard/create-whatsnew', element: <CreateWhatsNewPage /> },
];
