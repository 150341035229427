// File: src/components/ModuleRoute.jsx
import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import LockedPage from '../pages/LockedPage';

export default function ModuleRoute({ children, requiredModule }) {
  const { userProfile } = useContext(AuthContext);

  // If there's no userProfile or no 'selectedModules' field, block
  const userModules = userProfile?.selectedModules || [];

  if (!userModules.includes(requiredModule)) {
    // If missing the required module, show locked or redirect
    return <LockedPage />;
  }

  // Otherwise, user has the required module
  return children;
}
