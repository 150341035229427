import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Chip,
  Stack,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
} from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import AssignmentIcon from '@mui/icons-material/Assignment';
import FlagIcon from '@mui/icons-material/Flag';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AddIcon from '@mui/icons-material/Add';

import { CRMContext } from '/Users/willradley/orthinas-crm/src/context/CRMContext.js';

/**
 * Possible status options for each item type. 
 * Extend or modify these options as RCF Orthinas evolves,
 * potentially leveraging AI to recommend status changes.
 */
const STATUS_OPTIONS = {
  task: ['Not Started', 'In Progress', 'Completed', 'Blocked'],
  goal: ['Open', 'In Progress', 'Completed'],
  event: ['Scheduled', 'In Progress', 'Done', 'Canceled'],
};

/**
 * WorkManager
 * 
 * A single-pane approach to managing Tasks, Goals, and Events.
 * Showcases RCF Orthinas’s user-centric design, offering quick 
 * creation and filtering of items all in one place.
 * 
 * Future Direction:
 *  - Integrate AI to suggest due dates or priority levels 
 *    based on usage patterns.
 *  - Provide advanced filtering and sorting using machine 
 *    learning insights.
 */
export default function WorkManager() {
  const {
    tasks = [],
    goals = [],
    events = [],
    addNewTask,
    addNewGoal,
    addNewEvent,
  } = useContext(CRMContext);

  /** ------------------------------------------------------------------
   *  State: aggregated "Work" array + filters/search
   * ------------------------------------------------------------------*/
  const [allWork, setAllWork] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterType, setFilterType] = useState('all');

  /** ------------------------------------------------------------------
   *  Dialog State: controlling the "Create New" flow
   * ------------------------------------------------------------------*/
  const [dialogOpen, setDialogOpen] = useState(false);
  const [itemType, setItemType] = useState('task');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState(STATUS_OPTIONS.task[0]);

  // Shared date fields
  const [date, setDate] = useState(null); // For tasks/goals
  // For events
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isAllDay, setIsAllDay] = useState(false);

  /**
   * Combines tasks/goals/events into a single list, 
   * applies filtering by type and search term,
   * then sets the aggregated data in state.
   */
  useEffect(() => {
    const combined = [
      ...tasks.map((t) => ({ type: 'task', ...t })),
      ...goals.map((g) => ({ type: 'goal', ...g })),
      ...events.map((e) => ({ type: 'event', ...e })),
    ];

    let filtered = combined;
    if (filterType !== 'all') {
      filtered = filtered.filter((item) => item.type === filterType);
    }

    if (searchTerm.trim()) {
      const lowerSearch = searchTerm.toLowerCase();
      filtered = filtered.filter((item) =>
        (item.title || '').toLowerCase().includes(lowerSearch)
      );
    }

    setAllWork(filtered);
  }, [tasks, goals, events, filterType, searchTerm]);

  /** ------------------------------------------------------------------
   *  Handlers
   * ------------------------------------------------------------------*/
  const handleOpenDialog = () => {
    setDialogOpen(true);
    // Reset fields each time dialog is opened
    setItemType('task');
    setTitle('');
    setDescription('');
    setStatus(STATUS_OPTIONS.task[0]);
    setDate(null);
    setStartDate(null);
    setEndDate(null);
    setIsAllDay(false);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  /**
   * Adds a new item (Task/Goal/Event) through the CRMContext.
   * On success, closes the dialog.
   */
  const handleCreateItem = async () => {
    if (!title.trim()) return; // Basic validation

    try {
      if (itemType === 'task') {
        await addNewTask({
          title,
          description,
          status,
          dueDate: date ? date.toISOString() : null,
        });
      } else if (itemType === 'goal') {
        await addNewGoal({
          title,
          description,
          status,
          targetDate: date ? date.toISOString() : null,
        });
      } else if (itemType === 'event') {
        await addNewEvent({
          title,
          description,
          status,
          startDate: startDate ? startDate.toISOString() : null,
          endDate: endDate ? endDate.toISOString() : null,
          isAllDay,
        });
      }
      handleCloseDialog();
    } catch (error) {
      console.error('Error creating new item:', error);
    }
  };

  /**
   * Renders date-related fields based on the selected item type.
   * 
   * - "task" & "goal": single date picker
   * - "event": two date pickers + "All Day" checkbox
   */
  const renderExtraFields = () => {
    if (itemType === 'task' || itemType === 'goal') {
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label={itemType === 'task' ? 'Due Date' : 'Target Date'}
            value={date}
            onChange={(newValue) => setDate(newValue)}
            renderInput={(params) => <TextField size="small" {...params} />}
          />
        </LocalizationProvider>
      );
    }
    if (itemType === 'event') {
      return (
        <>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Start Date"
              value={startDate}
              onChange={(val) => setStartDate(val)}
              renderInput={(params) => <TextField size="small" {...params} />}
            />
            <DesktopDatePicker
              label="End Date"
              value={endDate}
              onChange={(val) => setEndDate(val)}
              renderInput={(params) => <TextField size="small" {...params} />}
            />
          </LocalizationProvider>
          <FormControlLabel
            control={
              <Checkbox
                checked={isAllDay}
                onChange={(e) => setIsAllDay(e.target.checked)}
              />
            }
            label="All Day Event?"
          />
        </>
      );
    }
    return null;
  };

  /** ------------------------------------------------------------------
   *  Render
   * ------------------------------------------------------------------*/
  return (
    <Box sx={{ p: 2, width: '100%' }}>
      {/* Control Panel: Search, Filter, Add */}
      <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 2 }}>
        {/* Search */}
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ flex: 1 }}
        />

        {/* Filter by Type */}
        <FormControl size="small" sx={{ minWidth: 100 }}>
          <InputLabel>Filter</InputLabel>
          <Select
            label="Filter"
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="task">Tasks</MenuItem>
            <MenuItem value="goal">Goals</MenuItem>
            <MenuItem value="event">Events</MenuItem>
          </Select>
        </FormControl>

        {/* Add Button */}
        <IconButton
          color="primary"
          aria-label="Add"
          onClick={handleOpenDialog}
        >
          <AddIcon />
        </IconButton>
      </Stack>

      <Divider sx={{ mb: 2 }} />

      {/* Display Items List */}
      <List disablePadding>
        {allWork.map((item, idx) => {
          let icon = <AssignmentIcon fontSize="small" />;
          let label = 'Task';

          if (item.type === 'goal') {
            icon = <FlagIcon fontSize="small" />;
            label = 'Goal';
          } else if (item.type === 'event') {
            icon = <EventNoteIcon fontSize="small" />;
            label = 'Event';
          }

          return (
            <React.Fragment key={item.id || idx}>
              <ListItem disableGutters sx={{ py: 1 }}>
                <ListItemIcon sx={{ minWidth: 32 }}>{icon}</ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="body2" fontWeight="bold" noWrap>
                      {item.title || `Untitled ${label}`}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="caption" color="text.secondary" noWrap>
                      {label}
                    </Typography>
                  }
                />

                {/* Status chip with simple color coding */}
                {item.status && (
                  <Chip
                    label={item.status}
                    size="small"
                    sx={{ ml: 1, textTransform: 'capitalize' }}
                    color={
                      item.status?.toLowerCase() === 'completed'
                        ? 'success'
                        : item.status?.toLowerCase() === 'in progress'
                        ? 'warning'
                        : 'default'
                    }
                  />
                )}
              </ListItem>
              {idx < allWork.length - 1 && <Divider component="li" />}
            </React.Fragment>
          );
        })}

        {/* Empty State */}
        {allWork.length === 0 && (
          <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
            No tasks, goals, or events found.
          </Typography>
        )}
      </List>

      {/* Dialog: Create a new item */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="xs" fullWidth>
        <DialogTitle>Create a New Work Item</DialogTitle>
        <DialogContent sx={{ mt: 1 }}>
          <Stack spacing={2}>
            {/* Item Type (Task, Goal, or Event) */}
            <FormControl size="small" fullWidth>
              <InputLabel>Type</InputLabel>
              <Select
                label="Type"
                value={itemType}
                onChange={(e) => {
                  const newType = e.target.value;
                  setItemType(newType);
                  setStatus(STATUS_OPTIONS[newType][0]); // default status for new type
                }}
              >
                <MenuItem value="task">Task</MenuItem>
                <MenuItem value="goal">Goal</MenuItem>
                <MenuItem value="event">Event</MenuItem>
              </Select>
            </FormControl>

            {/* Title */}
            <TextField
              label="Title"
              variant="outlined"
              size="small"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />

            {/* Description */}
            <TextField
              label="Description"
              variant="outlined"
              size="small"
              fullWidth
              multiline
              minRows={2}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />

            {/* Status */}
            <FormControl size="small" fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                label="Status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                {STATUS_OPTIONS[itemType].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Conditional Date Fields */}
            {renderExtraFields()}
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseDialog} color="inherit">
            Cancel
          </Button>
          <Button onClick={handleCreateItem} variant="contained">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
