// File: src/components/Navbar/DesktopLoggedOutNavItems.jsx

import React, { useState } from 'react';
import { Stack, Tooltip, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
// Existing Menus
import BigProductMegaMenu from '../Components/Navbar/BigProductMegaMenu';
import BigSolutionsMegaMenu from '../Components/Navbar/BigSolutionsMegaMenu';
// NEW:
import BigResourcesMegaMenu from '../Components/Navbar/BigResourcesMegaMenu';

function DesktopNavItem({ label, path, icon: IconComponent, buttonStyles }) {
  return (
    <Tooltip title={label} arrow>
      <Button
        component={Link}
        to={path}
        startIcon={IconComponent ? <IconComponent /> : null}
        sx={buttonStyles}
      >
        {label}
      </Button>
    </Tooltip>
  );
}

export default function DesktopLoggedOutNavItems({ buttonStyles }) {
  // "Product" mega menu
  const [productAnchorEl, setProductAnchorEl] = useState(null);
  const openProductMenu = Boolean(productAnchorEl);
  const handleProductMenuOpen = (e) => setProductAnchorEl(e.currentTarget);
  const handleProductMenuClose = () => setProductAnchorEl(null);

  // "Solutions" mega menu
  const [solutionsAnchorEl, setSolutionsAnchorEl] = useState(null);
  const openSolutionsMenu = Boolean(solutionsAnchorEl);
  const handleSolutionsMenuOpen = (e) => setSolutionsAnchorEl(e.currentTarget);
  const handleSolutionsMenuClose = () => setSolutionsAnchorEl(null);

  // NEW: "Resources" mega menu
  const [resourcesAnchorEl, setResourcesAnchorEl] = useState(null);
  const openResourcesMenu = Boolean(resourcesAnchorEl);
  const handleResourcesMenuOpen = (e) => setResourcesAnchorEl(e.currentTarget);
  const handleResourcesMenuClose = () => setResourcesAnchorEl(null);

  const publicNavItems = [
    { label: 'Pricing', path: '/pricing' },
  ];

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      {/* 1) Product Menu Trigger */}
      <Box>
        <Tooltip title="Product" arrow>
          <Button onClick={handleProductMenuOpen} sx={buttonStyles}>
            Product
          </Button>
        </Tooltip>
        <BigProductMegaMenu
          anchorEl={productAnchorEl}
          open={openProductMenu}
          onClose={handleProductMenuClose}
        />
      </Box>

      {/* 2) Solutions Menu Trigger */}
      <Box>
        <Tooltip title="Solutions" arrow>
          <Button onClick={handleSolutionsMenuOpen} sx={buttonStyles}>
            Solutions
          </Button>
        </Tooltip>
        <BigSolutionsMegaMenu
          anchorEl={solutionsAnchorEl}
          open={openSolutionsMenu}
          onClose={handleSolutionsMenuClose}
        />
      </Box>

      {/* 3) Resources Menu Trigger */}
      <Box>
        <Tooltip title="Resources" arrow>
          <Button onClick={handleResourcesMenuOpen} sx={buttonStyles}>
            Resources
          </Button>
        </Tooltip>
        <BigResourcesMegaMenu
          anchorEl={resourcesAnchorEl}
          open={openResourcesMenu}
          onClose={handleResourcesMenuClose}
        />
      </Box>

      {/* Additional Nav Items */}
      {publicNavItems.map((item) => (
        <DesktopNavItem
          key={item.label}
          label={item.label}
          path={item.path}
          icon={item.icon}
          buttonStyles={buttonStyles}
        />
      ))}
    </Stack>
  );
}
