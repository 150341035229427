// File: src/utils/sectionUtils.js

import React from 'react';
// Example icons you might use
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CampaignIcon from '@mui/icons-material/Campaign';
import ConstructionIcon from '@mui/icons-material/Construction';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ReceiptIcon from '@mui/icons-material/Receipt';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import StoreIcon from '@mui/icons-material/Store';
import PaymentIcon from '@mui/icons-material/Payment';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';

// 1) The “sectionsConfig” object
export const sectionsConfig = {
  sales: {
    prefix: '/sales',
    label: 'Sales',
    icon: <ShoppingCartIcon sx={{ fontSize: '1.25rem' }} />,
    background: 'linear-gradient(to right, #ba000d, #8a0000)',
    navItems: [
      { label: 'Deals', path: '/sales/deals', icon: <RequestQuoteIcon fontSize="small" /> },
      { label: 'Contacts', path: '/sales/contacts', icon: <SupportAgentIcon fontSize="small" /> },
      // etc...
    ],
  },
  marketing: {
    prefix: '/marketing',
    label: 'Marketing',
    icon: <CampaignIcon sx={{ fontSize: '1.25rem' }} />,
    background: 'linear-gradient(to right, #ff9100, #ff6d00)',
    navItems: [
      { label: 'Campaigns', path: '/marketing/campaigns', icon: <CampaignIcon fontSize="small" /> },
      { label: 'Forms', path: '/marketing/forms', icon: <BuildCircleIcon fontSize="small" /> },
      // etc...
    ],
  },
  operations: {
    prefix: '/operations',
    label: 'Operations',
    icon: <ConstructionIcon sx={{ fontSize: '1.25rem' }} />,
    background: 'linear-gradient(to right, #33691e, #1b5e20)',
    navItems: [
      { label: 'Automations', path: '/operations/automations', icon: <ConstructionIcon fontSize="small" /> },
      { label: 'Data Sync', path: '/operations/datasync', icon: <BuildCircleIcon fontSize="small" /> },
      { label: 'Data Quality', path: '/operations/dataquality', icon: <BuildCircleIcon fontSize="small" /> },
      // etc...
    ],
  },
  service: {
    prefix: '/service',
    label: 'Service',
    icon: <SupportAgentIcon sx={{ fontSize: '1.25rem' }} />,
    background: 'linear-gradient(to right, #0097a7, #00838f)',
    navItems: [
      { label: 'Cases', path: '/service/cases', icon: <SupportAgentIcon fontSize="small" /> },
      // etc...
    ],
  },
  content: {
    prefix: '/content',
    label: 'Content',
    icon: <DesignServicesIcon sx={{ fontSize: '1.25rem' }} />,
    background: 'linear-gradient(to right, #6a1b9a, #4a148c)',
    navItems: [
      { label: 'Landing Pages', path: '/content/landingpages', icon: <StoreIcon fontSize="small" /> },
      { label: 'Video/Podcasts', path: '/content/videopodcasts', icon: <DesignServicesIcon fontSize="small" /> },
      { label: 'SEO', path: '/content/seo', icon: <DesignServicesIcon fontSize="small" /> },
      // etc...
    ],
  },
  commerce: {
    prefix: '/commerce',
    label: 'Commerce',
    icon: <StorefrontIcon sx={{ fontSize: '1.25rem' }} />,
    background: 'linear-gradient(to right, #00695c, #004d40)',
    navItems: [
      { label: 'Invoices', path: '/commerce/invoices', icon: <ReceiptIcon fontSize="small" /> },
      { label: 'Quotes', path: '/commerce/quotes', icon: <RequestQuoteIcon fontSize="small" /> },
      { label: 'Catalog', path: '/commerce/catalog', icon: <StoreIcon fontSize="small" /> },
      { label: 'Payment Links', path: '/commerce/paymentlinks', icon: <PaymentIcon fontSize="small" /> },
      { label: 'Subscriptions', path: '/commerce/subscriptions', icon: <SubscriptionsIcon fontSize="small" /> },
      // etc.
    ],
  },
};

// 2) The function to detect which section (if any) from the pathname
export function getSectionConfig(pathname) {
  for (const key in sectionsConfig) {
    const sec = sectionsConfig[key];
    if (pathname.startsWith(sec.prefix)) {
      return sec; 
    }
  }
  return null; // No match => no sub nav
}
