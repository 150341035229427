// src/services/goals.js
import { db } from '../firebase';
import {
  collection,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
  Timestamp
} from 'firebase/firestore';

// Reference to the "goals" collection in Firestore
const goalsRef = collection(db, 'goals');

export async function getAllGoals() {
  const snapshot = await getDocs(goalsRef);
  return snapshot.docs.map((docSnap) => ({
    id: docSnap.id,
    ...docSnap.data(),
  }));
}

export async function addGoal(goalData) {
  const newGoal = {
    ...goalData,
    createdAt: Timestamp.now(),
  };
  const docRef = await addDoc(goalsRef, newGoal);
  return docRef.id;
}

export async function updateGoal(goalId, updateData) {
  const goalDoc = doc(db, 'goals', goalId);
  await updateDoc(goalDoc, {
    ...updateData,
    updatedAt: Timestamp.now(),
  });
}

export async function deleteGoal(goalId) {
  const goalDoc = doc(db, 'goals', goalId);
  await deleteDoc(goalDoc);
}
