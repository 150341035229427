// src/components/Navbar/WorkMenu.jsx
import React from 'react';
import {
  Menu,
  Grow,
  Typography,
  IconButton,
  Stack,
  Paper,
  Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import WorkManager from './WorkManager'; // Adjust path as needed

/**
 * WorkMenu Component
 * 
 * Displays an overlay of current Goals, Tasks, and Events, 
 * powered by RCF Orthinas’s AI-driven organizational tools.
 * 
 *  - The optional header area highlights the menu’s function.
 *  - When open, a dedicated <WorkManager> handles 
 *    tasks, goals, and events inside a scrollable panel.
 *  - Helps users keep track of daily workload with a 
 *    polished look and brand-centric style.
 *
 * @param {object} props
 * @param {HTMLElement} props.anchorEl - The reference element to anchor the menu.
 * @param {boolean} props.open - Whether the menu is open.
 * @param {function} props.onClose - Callback to close the menu.
 */
function WorkMenu({ anchorEl, open, onClose }) {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      TransitionComponent={Grow}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      aria-label="Work menu"
      sx={{
        '& .MuiMenu-paper': {
          width: 420,
          maxHeight: 580,
          p: 0,
          border: '1px solid #ddd',
          boxShadow: 3,
          borderRadius: 1,
          overflow: 'hidden', // Ensures header remains fixed
        },
      }}
    >
      {/* Header: Title + Close Button */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ px: 2, py: 1.5 }}
      >
        <Typography variant="subtitle1" fontWeight="bold">
          Goals, Tasks &amp; Events
        </Typography>
        <IconButton
          aria-label="close work menu"
          size="small"
          onClick={onClose}
          sx={{ ml: 'auto' }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Stack>

      <Divider />

      {/* Scrollable Content: Integrates with WorkManager */}
      <Paper
        elevation={0}
        sx={{
          maxHeight: 520,
          overflowY: 'auto',
        }}
      >
        {/* Render WorkManager only when menu is open for performance optimization */}
        {open && <WorkManager />}
      </Paper>
    </Menu>
  );
}

export default WorkMenu;
