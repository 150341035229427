// src/components/Navbar/MobileMenu.jsx

import React from 'react';
import {
  Menu,
  Grow,
  Box,
  Typography,
  Divider,
  MenuItem,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { Link } from 'react-router-dom';

// Icons
import {
  Dashboard as DashboardIcon,
  Assessment as AssessmentIcon,
  Logout as LogoutIcon,
  Settings as SettingsIcon,
  BusinessCenter as BusinessCenterIcon,
  Contacts as ContactsIcon,
  Campaign as CampaignIcon,
  DynamicForm as FormIcon,
  HelpCenter as HelpCenterIcon,
  LibraryBooks as LibraryBooksIcon,
  Web as WebIcon,
  OndemandVideo as OndemandVideoIcon,
  Search as SearchIcon,
  SettingsSuggest as SettingsSuggestIcon,
  Sync as SyncIcon,
  FactCheck as FactCheckIcon,
  RequestQuote as RequestQuoteIcon,
  Receipt as ReceiptIcon,
  Subscriptions as SubscriptionsIcon,
  Payment as PaymentIcon,
  Storefront as StorefrontIcon,
} from '@mui/icons-material';

/** -------------------------------------------
 *  A) Menu data for logged-in users
 * ------------------------------------------- */
const mobileSections = [
  {
    title: 'Sales',
    items: [
      { label: 'Deals', path: '/sales/deals', icon: <BusinessCenterIcon /> },
      { label: 'Contacts', path: '/sales/contacts', icon: <ContactsIcon /> },
    ],
  },
  {
    title: 'Marketing',
    items: [
      { label: 'Campaigns', path: '/marketing/campaigns', icon: <CampaignIcon /> },
      { label: 'Forms', path: '/marketing/forms', icon: <FormIcon /> },
    ],
  },
  {
    title: 'Service',
    items: [
      { label: 'Cases', path: '/cases', icon: <HelpCenterIcon /> },
      { label: 'Knowledge', path: '/service/knowledge', icon: <LibraryBooksIcon /> },
    ],
  },
  {
    title: 'Content',
    items: [
      { label: 'Landing Pages', path: '/content/landingpages', icon: <WebIcon /> },
      { label: 'Video & Podcast', path: '/content/videopodcasts', icon: <OndemandVideoIcon /> },
      { label: 'SEO', path: '/content/seo', icon: <SearchIcon /> },
    ],
  },
  {
    title: 'Operations',
    items: [
      { label: 'Automations', path: '/operations/automations', icon: <SettingsSuggestIcon /> },
      { label: 'Data Sync', path: '/operations/datasync', icon: <SyncIcon /> },
      { label: 'Quality Control', path: '/operations/dataquality', icon: <FactCheckIcon /> },
    ],
  },
  {
    title: 'Commerce',
    items: [
      { label: 'Quotes', path: '/commerce/quotes', icon: <RequestQuoteIcon /> },
      { label: 'Invoices', path: '/commerce/invoices', icon: <ReceiptIcon /> },
      { label: 'Subscriptions', path: '/commerce/subscriptions', icon: <SubscriptionsIcon /> },
      { label: 'Payment Links', path: '/commerce/paymentlinks', icon: <PaymentIcon /> },
      { label: 'Catalog', path: '/commerce/catalog', icon: <StorefrontIcon /> },
    ],
  },
];

/** -------------------------------------------
 *  B) Sub-components
 * ------------------------------------------- */

/**
 * UserGreeting
 * Renders the user's name/email at the top of the menu.
 */
function UserGreeting({ userNameOrEmail, userEmail }) {
  return (
    <Box px={2} pb={1}>
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
        Hello, {userNameOrEmail}
      </Typography>
      {userEmail && (
        <Typography variant="caption" color="text.secondary">
          {userEmail}
        </Typography>
      )}
    </Box>
  );
}

/**
 * SectionMenu
 * Renders a titled section (e.g. "Sales") with its sub-items.
 */
function SectionMenu({ section, onClose }) {
  return (
    <Box key={section.title} sx={{ mb: 1 }}>
      <Typography variant="subtitle2" sx={{ fontWeight: 'bold', px: 2, mt: 1 }}>
        {section.title}
      </Typography>
      {section.items.map((item) => (
        <MenuItem key={item.label} component={Link} to={item.path} onClick={onClose}>
          {React.cloneElement(item.icon, {
            fontSize: 'small',
            sx: { mr: 1 },
          })}
          {item.label}
        </MenuItem>
      ))}
      <Divider />
    </Box>
  );
}

/** -------------------------------------------
 *  C) Menu logic for logged-in vs. logged-out
 * ------------------------------------------- */

/** Logged-in menu items */
function LoggedInMenu({
  onClose,
  anchorEl,
  handleTasksMenuOpen,
  handleSignOut,
}) {
  return (
    <>
      <MenuItem component={Link} to="/dashboard" onClick={onClose}>
        <DashboardIcon fontSize="small" sx={{ mr: 1 }} />
        Home
      </MenuItem>

      <Divider sx={{ my: 1 }} />

      {mobileSections.map((section) => (
        <SectionMenu key={section.title} section={section} onClose={onClose} />
      ))}

      {/* Manage Tasks & Settings */}
      <MenuItem
        onClick={() => {
          // trick: re-use the anchorEl for the WorkMenu
          handleTasksMenuOpen({ currentTarget: anchorEl });
          onClose();
        }}
      >
        <AssessmentIcon fontSize="small" sx={{ mr: 1 }} />
        Manage Tasks
      </MenuItem>
      <MenuItem component={Link} to="/settings" onClick={onClose}>
        <SettingsIcon fontSize="small" sx={{ mr: 1 }} />
        Settings
      </MenuItem>

      <Divider sx={{ my: 1 }} />

      {/* Sign Out */}
      <MenuItem
        onClick={() => {
          handleSignOut();
          onClose();
        }}
        sx={{ color: 'error.main' }}
      >
        <LogoutIcon fontSize="small" sx={{ mr: 1 }} />
        Logout
      </MenuItem>
    </>
  );
}

/** Logged-out menu items */
function LoggedOutMenu({ onClose }) {
  return (
    <>
      <MenuItem component={Link} to="/pricing" onClick={onClose}>
        Pricing
      </MenuItem>
      <Divider sx={{ my: 2 }} />

      <MenuItem component={Link} to="/signin" onClick={onClose}>
        Sign In
      </MenuItem>
      <MenuItem component={Link} to="/signup" onClick={onClose}>
        Sign Up
      </MenuItem>
    </>
  );
}

/** -------------------------------------------
 *  D) Main Component
 * ------------------------------------------- */
export default function MobileMenu(props) {
  const {
    anchorEl,
    open,
    onClose,
    currentUser,
    userNameOrEmail,
    userEmail,
    handleSignOut,
    handleTasksMenuOpen,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      TransitionComponent={Grow}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      // The key fix: limit height + allow scrolling.
      sx={{
        '& .MuiMenu-paper': {
          width: isMobile ? '100%' : 260,
          p: 1,
          borderRadius: 2,
          // The two lines below ensure scrolling:
          maxHeight: '80vh',
          overflowY: 'auto',
        },
      }}
      aria-label="Mobile menu"
    >
      {/** If user is logged in, show greeting */}
      {currentUser && (
        <>
          <UserGreeting userNameOrEmail={userNameOrEmail} userEmail={userEmail} />
          <Divider />
        </>
      )}

      {currentUser ? (
        <LoggedInMenu
          onClose={onClose}
          anchorEl={anchorEl}
          handleTasksMenuOpen={handleTasksMenuOpen}
          handleSignOut={handleSignOut}
        />
      ) : (
        <LoggedOutMenu onClose={onClose} />
      )}
    </Menu>
  );
}
