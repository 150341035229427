import { db } from '../firebase';
import {
  collection,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
  Timestamp
} from 'firebase/firestore';

// Reference to "cases" collection in Firestore
const casesRef = collection(db, 'cases');

/**
 * Fetch all cases from Firestore.
 * @returns {Promise<Array>} - Array of case objects.
 */
export async function getAllCases() {
  const snapshot = await getDocs(casesRef);
  return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
}

/**
 * Add a new case document to Firestore.
 * @param {Object} caseData - The case data to store.
 * @returns {Promise<string>} - The newly created doc ID.
 */
export async function addCaseToFirestore(caseData) {
  const newCase = {
    ...caseData,
    createdAt: Timestamp.now()
  };
  const docRef = await addDoc(casesRef, newCase);
  return docRef.id;
}

/**
 * Update an existing case document.
 * @param {string} caseId - Firestore doc ID of the case to update.
 * @param {Object} updateData - Fields to update.
 */
export async function updateCaseInFirestore(caseId, updateData) {
  const caseDoc = doc(db, 'cases', caseId);
  await updateDoc(caseDoc, {
    ...updateData,
    updatedAt: Timestamp.now()
  });
}

/**
 * Delete an existing case document by ID.
 * @param {string} caseId - Firestore doc ID of the case to delete.
 */
export async function deleteCaseFromFirestore(caseId) {
  const caseDoc = doc(db, 'cases', caseId);
  await deleteDoc(caseDoc);
}
