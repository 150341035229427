// src/components/Navbar/DesktopLoggedOutRightItems.jsx

import React from 'react';
import { Tooltip, IconButton, Button, Stack, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import { HelpOutline as HelpOutlineIcon } from '@mui/icons-material';

/**
 * IconTooltipButton
 * Renders an IconButton wrapped in a Tooltip.
 */
function IconTooltipButton({
  tooltip,
  ariaLabel,
  onClick,
  icon: IconComponent,
  anchorEl,
  sx,
}) {
  return (
    <Tooltip title={tooltip} arrow>
      <IconButton
        onClick={onClick}
        sx={sx}
        aria-label={ariaLabel}
        aria-haspopup="true"
        aria-expanded={Boolean(anchorEl)}
      >
        <IconComponent />
      </IconButton>
    </Tooltip>
  );
}

/**
 * OutlinedButtonLink
 * Renders an outlined Button wrapped in a Tooltip that links to a path.
 */
function OutlinedButtonLink({
  tooltip,
  ariaLabel,
  to,
  label,
  buttonStyles,
}) {
  return (
    <Tooltip title={tooltip} arrow>
      <Button
        component={Link}
        to={to}
        variant="outlined"
        sx={{
          ...buttonStyles,
          backgroundColor: 'transparent',
          borderColor: 'rgba(255,255,255,0.7)',
          '&:hover': {
            borderColor: '#fff',
            backgroundColor: 'rgba(255,255,255,0.2)',
          },
        }}
        aria-label={ariaLabel}
      >
        {label}
      </Button>
    </Tooltip>
  );
}

export default function DesktopLoggedOutRightItems({
  buttonStyles,
  iconButtonStyles,
  handleHelpMenuOpen,
  helpAnchorEl,
}) {
  // Configuration for the sign-in/sign-up buttons
  const authButtons = [
    {
      tooltip: 'Sign in to your account',
      ariaLabel: 'Sign in',
      to: '/signin',
      label: 'Sign In',
    },
    {
      tooltip: 'Create a new account',
      ariaLabel: 'Sign up',
      to: '/signup',
      label: 'Sign Up',
    },
  ];

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      {/* Help & Support Icon */}
      <IconTooltipButton
        tooltip="Help & Support"
        ariaLabel="Help and Support"
        onClick={handleHelpMenuOpen}
        icon={HelpOutlineIcon}
        anchorEl={helpAnchorEl}
        sx={iconButtonStyles}
      />

      {/* Vertical divider */}
      <Divider
        orientation="vertical"
        flexItem
        sx={{ borderColor: 'rgba(255,255,255,0.3)' }}
      />

      {/* Map over sign-in and sign-up button configs */}
      {authButtons.map(({ tooltip, ariaLabel, to, label }) => (
        <OutlinedButtonLink
          key={label}
          tooltip={tooltip}
          ariaLabel={ariaLabel}
          to={to}
          label={label}
          buttonStyles={buttonStyles}
        />
      ))}
    </Stack>
  );
}
