import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  Menu,
  MenuList,
  MenuItem,
  Box,
  Grid,
  Typography,
  Link as MuiLink,
  CircularProgress,
  Alert,
  Button,
  TextField,
  InputAdornment,
  Fade,
  Chip,
  Divider,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '/Users/willradley/orthinas-crm/src/firebase.js';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import StarIcon from '@mui/icons-material/Star';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// OPTIONAL: For debouncing the search input
function useDebounce(value, delay = 300) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
}

/**
 * Splits an array of categories into N roughly equal columns.
 */
function chunkCategories(categoriesArray, columns) {
  const perColumn = Math.ceil(categoriesArray.length / columns);
  const chunks = [];
  for (let i = 0; i < columns; i++) {
    const start = i * perColumn;
    const end = start + perColumn;
    chunks.push(categoriesArray.slice(start, end));
  }
  return chunks;
}

/**
 * A small helper component that highlights the first occurrence of `query` inside `text`.
 */
function HighlightedText({ text, query }) {
  if (!query) return <>{text}</>;

  const lowerText = text.toLowerCase();
  const lowerQuery = query.toLowerCase();
  const index = lowerText.indexOf(lowerQuery);
  if (index === -1) {
    return <>{text}</>;
  }

  const before = text.slice(0, index);
  const match = text.slice(index, index + query.length);
  const after = text.slice(index + query.length);

  return (
    <>
      {before}
      <strong>{match}</strong>
      {after}
    </>
  );
}

/**
 * Categories you want to highlight from a marketing perspective.
 */
const FEATURED_CATEGORIES = new Set(['AI', 'Automation']);

export default function BigProductMegaMenu({ anchorEl, open, onClose }) {
  // State: features data, loading/error states
  const [features, setFeatures] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Skip re-fetch if we've already loaded once
  const fetchedOnceRef = useRef(false);

  // Search term for local filtering
  const [searchTerm, setSearchTerm] = useState('');
  const searchInputRef = useRef(null);

  // OPTIONAL: Debounced search term to avoid filtering on every keystroke
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  // Fetch from Firestore
  const fetchFeatures = async () => {
    setLoading(true);
    setError(null);
    try {
      const snapshot = await getDocs(collection(db, 'crmFeatures'));
      const loaded = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setFeatures(loaded);
    } catch (err) {
      console.error('Error fetching CRM features:', err);
      setError('Unable to load features. Please try again later.');
    } finally {
      setLoading(false);
      fetchedOnceRef.current = true;
    }
  };

  // On menu open, fetch data if not already done, and focus search
  useEffect(() => {
    if (open && anchorEl && !fetchedOnceRef.current) {
      fetchFeatures();
    }

    // Auto-focus the search field when the menu opens
    if (open) {
      setTimeout(() => {
        searchInputRef.current?.focus();
      }, 200);
    }
  }, [open, anchorEl]);

  // Retry button
  const handleRetry = () => {
    fetchedOnceRef.current = false;
    fetchFeatures();
  };

  // Filter features by search term
  const filteredFeatures = useMemo(() => {
    const query = debouncedSearchTerm.trim().toLowerCase();
    if (!query) return features;
    return features.filter((feat) => {
      const title = feat.title?.toLowerCase() || '';
      const desc = feat.description?.toLowerCase() || '';
      return title.includes(query) || desc.includes(query);
    });
  }, [features, debouncedSearchTerm]);

  // Group filtered features by category
  const groupedByCategory = useMemo(() => {
    const map = new Map();
    filteredFeatures.forEach((feat) => {
      const cat = feat.category || 'Other';
      if (!map.has(cat)) map.set(cat, []);
      map.get(cat).push(feat);
    });
    return Array.from(map.entries()).sort((a, b) => a[0].localeCompare(b[0]));
  }, [filteredFeatures]);

  // Split categories across 3 columns
  const columns = useMemo(() => {
    const categoryNames = groupedByCategory.map(([cat]) => cat);
    const [col1, col2, col3] = chunkCategories(categoryNames, 3);
    return [col1, col2, col3];
  }, [groupedByCategory]);

  // Grab features for a given category
  const getFeaturesByCategory = (categoryName) => {
    const found = groupedByCategory.find(([cat]) => cat === categoryName);
    return found ? found[1] : [];
  };

  // For fallback scenarios
  const totalFeatures = features.length;
  const totalFiltered = filteredFeatures.length;

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      TransitionComponent={Fade}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      PaperProps={{
        sx: {
          width: '900px',
          maxWidth: '95vw',
          p: 0,
          borderRadius: 2,
          boxShadow: 6,
          maxHeight: '75vh',
          overflowY: 'auto',
          // Gradient header background
          background: (theme) =>
            `linear-gradient(135deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
        },
      }}
      // For better screen reader labeling
      MenuListProps={{ 'aria-label': 'Feature categories' }}
    >
      {/* Inner wrapper for standard background */}
      <Box sx={{ p: 2, backgroundColor: 'background.paper' }}>
        {/* Search Bar */}
        <Box
          sx={{
            mb: 2,
            display: 'flex',
            gap: 1,
            alignItems: 'center',
            p: 1,
            borderRadius: 1,
            backgroundColor: (theme) => theme.palette.grey[50],
          }}
        >
          <TextField
            size="small"
            placeholder="Search features..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            variant="outlined"
            fullWidth
            inputRef={searchInputRef}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="disabled" />
                </InputAdornment>
              ),
            }}
            aria-label="Search features"
          />
          {searchTerm && (
            <Button onClick={() => setSearchTerm('')} color="inherit">
              Clear
            </Button>
          )}
        </Box>

        {/* Loading */}
        {loading && !error && (
          <Box display="flex" justifyContent="center" py={2}>
            <CircularProgress size={24} />
          </Box>
        )}

        {/* Error */}
        {error && (
          <Box sx={{ py: 1 }}>
            <Alert severity="error" sx={{ mb: 1 }}>
              {error}
            </Alert>
            <Button
              variant="contained"
              startIcon={<RefreshIcon />}
              onClick={handleRetry}
            >
              Retry
            </Button>
          </Box>
        )}

        {/* Main Content / Fallbacks */}
        {!loading && !error && (
          <>
            {totalFeatures === 0 ? (
              /* No data loaded at all */
              <Box textAlign="center" py={2}>
                <Typography variant="body1">
                  No features found. Please try again later.
                </Typography>
              </Box>
            ) : totalFiltered === 0 ? (
              /* Data loaded, but no matches for the current search */
              <Box textAlign="center" py={2}>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  No matching features found for “{searchTerm}”.
                </Typography>
                <Button
                  variant="text"
                  onClick={() => setSearchTerm('')}
                  size="small"
                >
                  Reset search
                </Button>
              </Box>
            ) : (
              /* We have data and at least some matches */
              <>
                <Grid container spacing={3}>
                  {columns.map((categoryChunk, idx) => (
                    <Grid item xs={12} sm={4} key={idx}>
                      {categoryChunk.map((category) => {
                        const featsInCat = getFeaturesByCategory(category);
                        if (!featsInCat?.length) return null;

                        const isFeatured = FEATURED_CATEGORIES.has(category);

                        return (
                          <Box key={category} mb={3}>
                            {/* Category heading */}
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                              sx={{
                                mb: 1,
                                borderLeft: (theme) =>
                                  `4px solid ${
                                    isFeatured
                                      ? theme.palette.warning.main
                                      : theme.palette.grey[400]
                                  }`,
                                pl: 1,
                              }}
                            >
                              <Typography
                                variant="subtitle1"
                                sx={{ fontWeight: 'bold' }}
                              >
                                {category}
                              </Typography>
                              {isFeatured && (
                                <Chip
                                  label="Featured"
                                  icon={<StarIcon />}
                                  color="warning"
                                  size="small"
                                  sx={{ ml: 1 }}
                                />
                              )}
                            </Box>

                            {/* Feature list */}
                            <MenuList dense disablePadding>
                              {featsInCat.map((feat) => (
                                <MenuItem
                                  key={feat.id}
                                  component={Link}
                                  to={`/features/${feat.id}`}
                                  onClick={onClose}
                                  sx={{
                                    py: 0.5,
                                    px: 0,
                                    borderRadius: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    '&:hover': {
                                      backgroundColor: 'transparent',
                                      color: 'primary.main',
                                      textDecoration: 'underline',
                                    },
                                  }}
                                >
                                  {/* ICON (only if iconUrl exists) */}
                                  {feat.iconUrl && (
                                    <Box
                                      component="img"
                                      src={feat.iconUrl}
                                      alt={feat.title}
                                      sx={{
                                        width: 18,
                                        height: 18,
                                        objectFit: 'contain',
                                        mr: 1,
                                      }}
                                    />
                                  )}

                                  {/* Title with optional highlight */}
                                  <MuiLink
                                    component="span"
                                    underline="hover"
                                    sx={{
                                      display: 'inline-block',
                                      width: '100%',
                                      color: 'text.primary',
                                    }}
                                  >
                                    <HighlightedText
                                      text={feat.title || 'Untitled Feature'}
                                      query={searchTerm}
                                    />

                                    {/* Plan requirement if relevant */}
                                    {feat.requiredPlan && (
                                      <Typography
                                        variant="caption"
                                        component="span"
                                        sx={{
                                          ml: 1,
                                          color: 'error.main',
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        (Requires {feat.requiredPlan} Plan)
                                      </Typography>
                                    )}
                                  </MuiLink>
                                </MenuItem>
                              ))}
                            </MenuList>
                          </Box>
                        );
                      })}
                    </Grid>
                  ))}
                </Grid>

                <Divider sx={{ my: 3 }} />

                {/* Marketing CTA at the bottom */}
                <Box textAlign="center" mb={2}>
                  <Box display="flex" justifyContent="center" gap={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onClose}
                      component={Link}
                      to="/pricing"
                      endIcon={<ArrowForwardIcon />}
                    >
                      Start Free Trial
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={onClose}
                      component={Link}
                      to="/contact-sales"
                      endIcon={<ArrowForwardIcon />}
                    >
                      Contact Sales
                    </Button>
                  </Box>
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </Menu>
  );
}
