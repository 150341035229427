// src/components/Navbar/DesktopLoggedInRightItems.jsx

import React from 'react';
import {
  Tooltip,
  Button,
  IconButton,
  Avatar,
  Badge,
  Stack,
  Chip,
  Divider,
} from '@mui/material';
import { Link } from 'react-router-dom';
import {
  Star as StarIcon,
  HelpOutline as HelpOutlineIcon,
  Assessment as AssessmentIcon,
} from '@mui/icons-material';

/** -------------------------------------------
 *  A) Small helper functions/components
 * ------------------------------------------- */

/** Returns a user-friendly tooltip based on incomplete tasks count */
function getTasksTooltip(count = 0) {
  if (!count) return 'Manage Tasks';
  return `${count} incomplete task${count > 1 ? 's' : ''}`;
}

/** PlanChip - shows the user's current plan in a Chip. */
function PlanChip({ planLabel }) {
  if (!planLabel) return null;
  return (
    <Chip
      label={planLabel}
      color="primary"
      variant="outlined"
      sx={{ fontWeight: 'bold' }}
    />
  );
}

/** UpgradeButton - links to your pricing page with a star icon. */
function UpgradeButton({ buttonStyles }) {
  return (
    <Tooltip title="View pricing plans and upgrade" arrow>
      <Button
        component={Link}
        to="/pricing"
        variant="contained"
        sx={buttonStyles}
        aria-label="Upgrade your account"
        startIcon={<StarIcon fontSize="small" />}
      >
        Upgrade
      </Button>
    </Tooltip>
  );
}

/** HelpButton - opens a help/support menu. */
function HelpButton({ onClick, iconButtonStyles, helpAnchorEl }) {
  return (
    <Tooltip title="Help & Support" arrow>
      <IconButton
        onClick={onClick}
        sx={iconButtonStyles}
        aria-label="Help and Support"
        aria-haspopup="true"
        aria-expanded={Boolean(helpAnchorEl)}
      >
        <HelpOutlineIcon />
      </IconButton>
    </Tooltip>
  );
}

/** TasksButton - shows incomplete tasks count in a Badge. */
function TasksButton({
  onClick,
  iconButtonStyles,
  tasksAnchorEl,
  incompleteTasksCount,
}) {
  return (
    <Tooltip title={getTasksTooltip(incompleteTasksCount)} arrow>
      <IconButton
        onClick={onClick}
        sx={iconButtonStyles}
        aria-label="Manage tasks"
        aria-haspopup="true"
        aria-expanded={Boolean(tasksAnchorEl)}
      >
        <Badge badgeContent={incompleteTasksCount || 0} color="error">
          <AssessmentIcon />
        </Badge>
      </IconButton>
    </Tooltip>
  );
}

/** ProfileMenuButton - shows the user’s avatar and opens a profile menu. */
function ProfileMenuButton({
  onClick,
  avatarStyles,
  userNameOrEmail,
  userInitial,
  profileAnchorEl,
}) {
  return (
    <Tooltip
      title={`Open profile menu for ${userNameOrEmail || ''}`}
      arrow
    >
      <IconButton
        onClick={onClick}
        sx={{ p: 0 }}
        aria-label="Open profile menu"
        aria-haspopup="true"
        aria-expanded={Boolean(profileAnchorEl)}
      >
        <Avatar alt={userNameOrEmail} sx={avatarStyles}>
          {userInitial}
        </Avatar>
      </IconButton>
    </Tooltip>
  );
}

/** -------------------------------------------
 *  B) Main Component
 * ------------------------------------------- */
export default function DesktopLoggedInRightItems({
  planLabel,
  buttonStyles,
  iconButtonStyles,
  handleHelpMenuOpen,
  helpAnchorEl,
  handleTasksMenuOpen,
  tasksAnchorEl,
  incompleteTasksCount,
  handleProfileMenuOpen,
  profileAnchorEl,
  avatarStyles,
  userInitial,
  userNameOrEmail,
}) {
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      {/* 1) Optional Plan Label */}
      <PlanChip planLabel={planLabel} />

      {/* 2) Upgrade Button */}
      <UpgradeButton buttonStyles={buttonStyles} />

      {/* 3) Divider (optional) */}
      <Divider
        orientation="vertical"
        flexItem
        sx={{ mx: 1, borderColor: 'grey.300' }}
      />

      {/* 4) Help & Support */}
      <HelpButton
        onClick={handleHelpMenuOpen}
        iconButtonStyles={iconButtonStyles}
        helpAnchorEl={helpAnchorEl}
      />

      {/* 5) Tasks */}
      <TasksButton
        onClick={handleTasksMenuOpen}
        iconButtonStyles={iconButtonStyles}
        tasksAnchorEl={tasksAnchorEl}
        incompleteTasksCount={incompleteTasksCount}
      />

      {/* 6) Profile / Avatar */}
      <ProfileMenuButton
        onClick={handleProfileMenuOpen}
        avatarStyles={avatarStyles}
        userNameOrEmail={userNameOrEmail}
        userInitial={userInitial}
        profileAnchorEl={profileAnchorEl}
      />
    </Stack>
  );
}
