// File: src/App.js
import React, { Suspense } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Box, Toolbar } from '@mui/material';

// Context Providers
import { AuthProvider } from './context/AuthContext';
import { CRMProvider } from './context/CRMContext';
import { SubscriptionProvider } from './context/SubscriptionContext';
import ThemeProviderComponent from './context/ThemeProviderComponent';

// Layout & Components
import Footer from './components/layout/Components/Footer';
import Navbar from './components/layout/Components/Navbar';
import MultiSectionSubNav from './components/MultiSectionSubNav';
import LoadingSpinner from './components/LoadingSpinner';

// Route Guards
import ProtectedRoute from './components/ProtectedRoute';
import ModuleRoute from './components/ModuleRoute';
import LockedPage from './pages/LockedPage';

// Route config arrays
import { publicRoutes } from './routes/publicRoutes';
import { protectedRoutes } from './routes/protectedRoutes';
import { moduleProtectedRoutes } from './routes/moduleProtectedRoutes';

import ChatWidget from './components/ChatWidget/ChatWidget';  // adjust the path


// Utility
import { getSectionConfig } from './utils/sectionUtils';

/**
 * LoadingWrapper:
 * Simple fallback UI while routes are lazily loaded.
 */
function LoadingWrapper() {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
      <LoadingSpinner aria-label="Loading" />
    </Box>
  );
}

/**
 * AppRoutes:
 * Defines all routing logic, wrapped in <Suspense /> for code-split route components.
 */
function AppRoutes() {
  return (
    <Suspense fallback={<LoadingWrapper />}>
      <Routes>
        {/* Public (no login required) */}
        {publicRoutes.map((r) => (
          <Route key={r.path} path={r.path} element={r.element} />
        ))}

        {/* Protected (login required) */}
        {protectedRoutes.map((r) => (
          <Route
            key={r.path}
            path={r.path}
            element={<ProtectedRoute>{r.element}</ProtectedRoute>}
          />
        ))}

        {/* Module-Protected (login + module subscription) */}
        {moduleProtectedRoutes.map((r) => (
          <Route
            key={r.path}
            path={r.path}
            element={
              <ProtectedRoute>
                <ModuleRoute requiredModule={r.module || 'someDefault'}>
                  {r.element}
                </ModuleRoute>
              </ProtectedRoute>
            }
          />
        ))}

        {/* Fallback for locked features */}
        <Route path="/locked" element={<LockedPage />} />
      </Routes>
    </Suspense>
  );
}

export default function App() {
  const location = useLocation();

  // Decide if Nav/Footer is hidden for certain "public portal" pages
  const publicPortalPrefixes = [
    '/invoice-share',
    '/quote-share',
    '/portal/',
    '/subscription-share/',
    '/pay/',
  ];
  const hideNavFooter = publicPortalPrefixes.some((prefix) =>
    location.pathname.startsWith(prefix)
  );

  // Determine whether the sub-nav should appear
  const section = getSectionConfig(location.pathname);
  const isSubNavShowing = !!section;

  /**
   * If it's a "public portal" page, just render routes
   * (no main navbar, subnav, or footer).
   */
  if (hideNavFooter) {
    return (
      <ThemeProviderComponent>
        <AuthProvider>
          <CRMProvider>
            <SubscriptionProvider>
              <AppRoutes />
            </SubscriptionProvider>
          </CRMProvider>
        </AuthProvider>
      </ThemeProviderComponent>
    );
  }

  /**
   * Otherwise, render the full layout with:
   *  - Primary Navbar (fixed)
   *  - Conditional Sub-Nav (fixed below primary)
   *  - Main content (routes)
   *  - Footer
   */
  return (
    <ThemeProviderComponent>
      <AuthProvider>
        <CRMProvider>
          <SubscriptionProvider>
            <Box display="flex" flexDirection="column" minHeight="100vh">
              {/* Primary Navbar at top (fixed) */}
              <Navbar logo="./RCFOrthinasLight.png" />

              {/* Spacer for primary nav (height ~64px), ensures content starts below nav */}
              <Toolbar />

              {/* SubNav (if applicable) */}
              {isSubNavShowing && (
                <>
                  <MultiSectionSubNav />
                  {/* Another spacer for subnav's height (e.g., ~64px) */}
                  <Toolbar sx={{ minHeight: 64 }} />
                </>
              )}

              {/* Main content area - flex:1 to push Footer to the bottom */}
              <Box component="main" flex={1} display="flex" flexDirection="column">
                <AppRoutes />
              </Box>

              {/* Footer at the bottom */}
              <Footer />
              <ChatWidget />
            </Box>
          </SubscriptionProvider>
        </CRMProvider>
      </AuthProvider>
    </ThemeProviderComponent>
  );
}
