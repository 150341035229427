// Make sure to have firebase properly initialized in ../firebase
import { db } from '../firebase';
import { collection, getDocs, addDoc, doc, updateDoc, deleteDoc } from 'firebase/firestore';

export async function getAllContacts() {
  const snapshot = await getDocs(collection(db, 'contacts'));
  return snapshot.docs.map((docSnap) => ({
    ...docSnap.data(),
    id: docSnap.id, // Firestore doc.id is always a string
  }));
}

export async function addContact(contactData) {
  await addDoc(collection(db, 'contacts'), contactData);
}

export async function updateContact(contactId, updateData) {
  // contactId must be a Firestore doc ID string
  const docRef = doc(db, 'contacts', contactId);
  // updateDoc allows adding new fields that weren't previously present
  await updateDoc(docRef, updateData);
}

export async function deleteContact(contactId) {
  const docRef = doc(db, 'contacts', contactId);
  await deleteDoc(docRef);
}
