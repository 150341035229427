// /Users/willradley/orthinas-crm/src/services/tasks.js
import { db } from '../firebase';
import { collection, getDocs, addDoc, doc, updateDoc, deleteDoc, where, query } from 'firebase/firestore';

export async function getAllTasks(userId) {
  // Optionally filter tasks by userId if you want user-specific tasks
  const q = query(collection(db, 'tasks'), where('userId', '==', userId));
  const snapshot = await getDocs(q);
  return snapshot.docs.map((docSnap) => ({
    ...docSnap.data(),
    id: docSnap.id,
  }));
}

export async function addTask(taskData) {
  await addDoc(collection(db, 'tasks'), taskData);
}

export async function updateTask(taskId, updateData) {
  const docRef = doc(db, 'tasks', taskId);
  await updateDoc(docRef, updateData);
}

export async function deleteTask(taskId) {
  const docRef = doc(db, 'tasks', taskId);
  await deleteDoc(docRef);
}
