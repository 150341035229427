// src/components/Navbar/MobileLoggedInView.jsx

import React from 'react';
import { Tooltip, IconButton, Stack } from '@mui/material';
import {
  HelpOutline as HelpOutlineIcon,
  Menu as MenuIcon,
} from '@mui/icons-material';

/**
 * IconTooltipButton
 * Renders an IconButton wrapped in a Tooltip, 
 * with ARIA props for better accessibility.
 */
function IconTooltipButton({ title, label, IconComponent, onClick, sx, anchor }) {
  return (
    <Tooltip title={title} arrow>
      <IconButton
        onClick={onClick}
        sx={sx}
        aria-label={label}
        aria-haspopup="true"
        aria-expanded={Boolean(anchor)}
      >
        <IconComponent />
      </IconButton>
    </Tooltip>
  );
}

export default function MobileLoggedInView({
  iconButtonStyles,
  handleHelpMenuOpen,
  helpAnchorEl,
  mobileAnchorEl,
  handleMobileMenuOpen,
}) {
  // A small config array for each icon+tooltip
  const actions = [
    {
      title: 'Help & Support',
      label: 'Help and Support',
      IconComponent: HelpOutlineIcon,
      onClick: handleHelpMenuOpen,
      anchor: helpAnchorEl,
    },
    {
      title: 'Open menu',
      label: 'Open mobile menu',
      IconComponent: MenuIcon,
      onClick: handleMobileMenuOpen,
      anchor: mobileAnchorEl,
    },
  ];

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {actions.map((action) => (
        <IconTooltipButton
          key={action.title}
          title={action.title}
          label={action.label}
          IconComponent={action.IconComponent}
          onClick={action.onClick}
          sx={iconButtonStyles}
          anchor={action.anchor}
        />
      ))}
    </Stack>
  );
}
