// useUserConversations.js
import { useEffect, useState, useCallback } from 'react';
import {
  collection,
  onSnapshot,
  query,
  where,
  orderBy,
  addDoc,
  serverTimestamp,
} from 'firebase/firestore';

/**
 * useUserConversations:
 *  - For a given user (identified by userId or guestId),
 *    fetches all conversation docs in the "conversations" collection.
 *  - Returns a list of { id, category, conversationId, createdAt, ... }.
 *  - Also provides a function to create a new conversation doc.
 */
export default function useUserConversations(db, conversationOwnerId) {
  const [conversationsList, setConversationsList] = useState([]);
  const [loadingConversations, setLoadingConversations] = useState(true);
  const [errorConversations, setErrorConversations] = useState(null);

  // 1) Subscribe to the user's conversations
  useEffect(() => {
    if (!conversationOwnerId) {
      setConversationsList([]);
      setLoadingConversations(false);
      return;
    }

    setLoadingConversations(true);

    const convRef = collection(db, 'conversations');
    const q = query(
      convRef,
      where('conversationIdOwner', '==', conversationOwnerId), // We'll store conversationIdOwner
      orderBy('createdAt', 'desc')
    );

    const unsub = onSnapshot(
      q,
      (snapshot) => {
        const list = snapshot.docs.map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data(),
        }));
        setConversationsList(list);
        setLoadingConversations(false);
      },
      (err) => {
        console.error('useUserConversations error:', err);
        setErrorConversations(err.message || 'Failed to load conversations');
        setLoadingConversations(false);
      }
    );

    return () => unsub();
  }, [db, conversationOwnerId]);

  // 2) Create a new conversation doc
  const createNewConversation = useCallback(async () => {
    if (!conversationOwnerId) return null;
    try {
      const docRef = await addDoc(collection(db, 'conversations'), {
        conversationId: `${conversationOwnerId}_${Date.now()}`, // or your own logic
        conversationIdOwner: conversationOwnerId, // helps query for user or guest
        category: null,
        createdAt: serverTimestamp(),
      });
      return docRef.id; // the Firestore doc ID
    } catch (err) {
      console.error('Error creating new conversation:', err);
      throw err;
    }
  }, [db, conversationOwnerId]);

  return {
    conversationsList,
    loadingConversations,
    errorConversations,
    createNewConversation,
  };
}
