// src/components/Navbar/DesktopLoggedInNavItems.jsx

import React from 'react';
import {
  Tooltip,
  Button,
  Menu,
  MenuItem,
  Grow,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { Link } from 'react-router-dom';

// Icons
import {
  BusinessCenter as BusinessCenterIcon,
  Contacts as ContactsIcon,
  Campaign as CampaignIcon,
  DynamicForm as FormIcon,
  HelpCenter as HelpCenterIcon,
  LibraryBooks as LibraryBooksIcon,
  Web as WebIcon,
  OndemandVideo as OndemandVideoIcon,
  Search as SearchIcon,
  SettingsSuggest as SettingsSuggestIcon,
  Sync as SyncIcon,
  FactCheck as FactCheckIcon,
  RequestQuote as RequestQuoteIcon,
  Receipt as ReceiptIcon,
  Subscriptions as SubscriptionsIcon,
  Payment as PaymentIcon,
  Storefront as StoreIcon,
} from '@mui/icons-material';

/**
 * DesktopLoggedInNavItems
 *
 * Renders a series of top-level menu items (Home, Sales, Marketing, etc.)
 * with optional submenus, used when a user is logged in on desktop view.
 * 
 * Features:
 *  - Some items (like 'Home') link directly to a path.
 *  - Items with children (e.g., 'Sales', 'Marketing') open a dropdown
 *    menu of related routes.
 * 
 * Why RCF Orthinas is Different:
 *  - Each nav section can be extended for Orthinas’s evolving AI-driven modules
 *    (e.g., Marketing could include an AI campaign optimizer in the future).
 *  - The streamlined design ensures users quickly find the tools they need—
 *    from Sales to Commerce—reducing friction and boosting productivity.
 *
 * @param {object} props
 * @param {object} props.buttonStyles - MUI sx styles object for top-level buttons.
 * @param {function} props.handleSalesMenuOpen - Handler to open the Sales menu.
 * @param {HTMLElement} props.salesAnchorEl - The anchorEl for the Sales menu.
 * @param {function} props.handleSalesMenuClose - Handler to close the Sales menu.
 * @param {function} props.handleMarketingMenuOpen - etc. for Marketing...
 * @param {HTMLElement} props.marketingAnchorEl
 * @param {function} props.handleMarketingMenuClose
 * @param {function} props.handleServiceMenuOpen - etc. for Service...
 * @param {HTMLElement} props.serviceAnchorEl
 * @param {function} props.handleServiceMenuClose
 * @param {function} props.handleContentMenuOpen - etc. for Content...
 * @param {HTMLElement} props.contentAnchorEl
 * @param {function} props.handleContentMenuClose
 * @param {function} props.handleOperationsMenuOpen - etc. for Operations...
 * @param {HTMLElement} props.operationsAnchorEl
 * @param {function} props.handleOperationsMenuClose
 * @param {function} props.handleCommerceMenuOpen - etc. for Commerce...
 * @param {HTMLElement} props.commerceAnchorEl
 * @param {function} props.handleCommerceMenuClose
 */
function DesktopLoggedInNavItems({
  buttonStyles,
  // Sales
  handleSalesMenuOpen,
  salesAnchorEl,
  handleSalesMenuClose,
  // Marketing
  handleMarketingMenuOpen,
  marketingAnchorEl,
  handleMarketingMenuClose,
  // Service
  handleServiceMenuOpen,
  serviceAnchorEl,
  handleServiceMenuClose,
  // Content
  handleContentMenuOpen,
  contentAnchorEl,
  handleContentMenuClose,
  // Operations
  handleOperationsMenuOpen,
  operationsAnchorEl,
  handleOperationsMenuClose,
  // Commerce
  handleCommerceMenuOpen,
  commerceAnchorEl,
  handleCommerceMenuClose,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  /**
   * We define a config array that describes each top-level nav item.
   * Each item can have:
   *  - label: the text for the button
   *  - path: if this is a direct link
   *  - anchorEl, onOpen, onClose: if it has a submenu
   *  - submenu: an array of objects { label, path, icon } for each sub-route
   */
  const navConfig = [
    {
      label: 'Home',
      path: '/dashboard',
      // Direct link - no submenu
    },
    {
      label: 'Sales',
      anchorEl: salesAnchorEl,
      onOpen: handleSalesMenuOpen,
      onClose: handleSalesMenuClose,
      submenu: [
        {
          label: 'Deals',
          path: '/sales/deals',
          icon: <BusinessCenterIcon fontSize="small" sx={{ mr: 1 }} />,
        },
        {
          label: 'Contacts',
          path: '/sales/contacts',
          icon: <ContactsIcon fontSize="small" sx={{ mr: 1 }} />,
        },
      ],
    },
    {
      label: 'Marketing',
      anchorEl: marketingAnchorEl,
      onOpen: handleMarketingMenuOpen,
      onClose: handleMarketingMenuClose,
      submenu: [
        {
          label: 'Campaigns',
          path: '/marketing/campaigns',
          icon: <CampaignIcon fontSize="small" sx={{ mr: 1 }} />,
        },
        {
          label: 'Forms',
          path: '/forms',
          icon: <FormIcon fontSize="small" sx={{ mr: 1 }} />,
        },
      ],
    },
    {
      label: 'Service',
      anchorEl: serviceAnchorEl,
      onOpen: handleServiceMenuOpen,
      onClose: handleServiceMenuClose,
      submenu: [
        {
          label: 'Cases',
          path: '/service/cases',
          icon: <HelpCenterIcon fontSize="small" sx={{ mr: 1 }} />,
        },
        {
          label: 'Knowledge',
          path: '/service/knowledge',
          icon: <LibraryBooksIcon fontSize="small" sx={{ mr: 1 }} />,
        },
      ],
    },
    {
      label: 'Content',
      anchorEl: contentAnchorEl,
      onOpen: handleContentMenuOpen,
      onClose: handleContentMenuClose,
      submenu: [
        {
          label: 'Landing Pages',
          path: '/content/landingpages',
          icon: <WebIcon fontSize="small" sx={{ mr: 1 }} />,
        },
        {
          label: 'Video & Podcast',
          path: '/content/videopodcasts',
          icon: <OndemandVideoIcon fontSize="small" sx={{ mr: 1 }} />,
        },
        {
          label: 'SEO',
          path: '/content/seo',
          icon: <SearchIcon fontSize="small" sx={{ mr: 1 }} />,
        },
      ],
    },
    {
      label: 'Operations',
      anchorEl: operationsAnchorEl,
      onOpen: handleOperationsMenuOpen,
      onClose: handleOperationsMenuClose,
      submenu: [
        {
          label: 'Automations',
          path: '/automations',
          icon: <SettingsSuggestIcon fontSize="small" sx={{ mr: 1 }} />,
        },
        {
          label: 'Data Sync',
          path: '/datasync',
          icon: <SyncIcon fontSize="small" sx={{ mr: 1 }} />,
        },
        {
          label: 'Quality Control',
          path: '/dataquality',
          icon: <FactCheckIcon fontSize="small" sx={{ mr: 1 }} />,
        },
      ],
    },
    {
      label: 'Commerce',
      anchorEl: commerceAnchorEl,
      onOpen: handleCommerceMenuOpen,
      onClose: handleCommerceMenuClose,
      submenu: [
        {
          label: 'Quotes',
          path: '/commerce/quotes',
          icon: <RequestQuoteIcon fontSize="small" sx={{ mr: 1 }} />,
        },
        {
          label: 'Invoices',
          path: '/commerce/invoices',
          icon: <ReceiptIcon fontSize="small" sx={{ mr: 1 }} />,
        },
        {
          label: 'Subscriptions',
          path: '/commerce/subscriptions',
          icon: <SubscriptionsIcon fontSize="small" sx={{ mr: 1 }} />,
        },
        {
          label: 'Payment Links',
          path: '/commerce/paymentlinks',
          icon: <PaymentIcon fontSize="small" sx={{ mr: 1 }} />,
        },
        {
          label: 'Catalog',
          path: '/commerce/catalog',
          icon: <StoreIcon fontSize="small" sx={{ mr: 1 }} />,
        },
      ],
    },
  ];

  return (
    <>
      {navConfig.map((navItem) => {
        // Direct Link (no submenu)
        if (!navItem.submenu) {
          return (
            <Tooltip key={navItem.label} title={navItem.label} arrow>
              <Button
                component={Link}
                to={navItem.path}
                sx={buttonStyles}
              >
                {navItem.label}
              </Button>
            </Tooltip>
          );
        }

        // Dropdown with Submenu Items
        const open = Boolean(navItem.anchorEl);

        return (
          <React.Fragment key={navItem.label}>
            <Tooltip title={navItem.label} arrow>
              <Button
                onClick={navItem.onOpen}
                sx={buttonStyles}
                aria-controls={`${navItem.label}-menu`}
                aria-haspopup="true"
              >
                {navItem.label}
              </Button>
            </Tooltip>

            <Menu
              id={`${navItem.label}-menu`}
              anchorEl={navItem.anchorEl}
              open={open}
              onClose={navItem.onClose}
              TransitionComponent={Grow}
              anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
              transformOrigin={{ horizontal: 'left', vertical: 'top' }}
              sx={{
                '& .MuiMenu-paper': {
                  // You can tweak width or add styling here
                  maxWidth: isMobile ? '100%' : 240,
                },
              }}
            >
              {navItem.submenu.map((sub) => (
                <MenuItem
                  key={sub.label}
                  component={Link}
                  to={sub.path}
                  onClick={navItem.onClose}
                >
                  {sub.icon}
                  {sub.label}
                </MenuItem>
              ))}
            </Menu>
          </React.Fragment>
        );
      })}
    </>
  );
}

export default DesktopLoggedInNavItems;
