// ChatWidget.js

import React, {
  useState,
  useContext,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import {
  Box,
  IconButton,
  Paper,
  Typography,
  Tooltip,
  Badge,
  Button,
  CircularProgress,
  Avatar,
  TextField,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  Chip,
} from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import MinimizeIcon from '@mui/icons-material/Remove';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SearchIcon from '@mui/icons-material/Search';

// Firebase & context
import { db } from '../../firebase';
import { AuthContext } from '../../context/AuthContext';

// Our custom hooks
import useGuestInfo from './useGuestInfo';
import useMessages from './useMessages';
import useConversationCategory from './useConversationCategory';
import useUserConversations from './useUserConversations';

// Our sub-component
import ChatInputBox from './ChatInputBox';

export default function ChatWidget() {
  /** ------------------------------------------------------------------
   *  1) HOOKS & CONTEXT
   * ------------------------------------------------------------------ */
  const { currentUser, userProfile } = useContext(AuthContext);

  // Staff/admin check (but still call hooks unconditionally to avoid hook-order errors)
  const isStaffOrAdmin =
    userProfile?.role === 'staff' || userProfile?.role === 'admin';

  const {
    guestId,
    guestEmail,
    emailInput,
    nameInput,
    setEmailInput,
    setNameInput,
    handleSaveGuestInfo,
  } = useGuestInfo(currentUser);

  const conversationOwnerId = currentUser?.uid || guestId;

  /** ------------------------------------------------------------------
   *  2) STATE (Widget & Convo Management)
   * ------------------------------------------------------------------ */
  const [isOpen, setIsOpen] = useState(false);    // Floating widget open/close
  const [isMinimized, setIsMinimized] = useState(false); // Minimize chat content
  const [view, setView] = useState('HOME'); // 'HOME' or 'CHAT'

  // The Firestore doc ID for the conversation & data
  const [activeConvDocId, setActiveConvDocId] = useState(null);
  const [activeConvData, setActiveConvData] = useState(null);

  // The conversationId used for messages
  const conversationId = activeConvData?.conversationId || null;

  /** ------------------------------------------------------------------
   *  3) FETCH CONVERSATIONS & MESSAGES
   * ------------------------------------------------------------------ */
  const {
    conversationsList,
    loadingConversations,
    errorConversations,
    createNewConversation,
  } = useUserConversations(db, conversationOwnerId);

  const { conversationCategory, handleSelectCategory } = useConversationCategory(
    db,
    conversationId
  );

  const {
    messages,
    messagesLoading,
    messagesError,
    setMessagesError,
    isConversationClosed,
    messagesEndRef,
    sendMessage,
  } = useMessages({
    db,
    conversationId,
    currentUser,
    userProfile,
    guestEmail,
    guestName: nameInput, // optional
  });

  // The user's typed message
  const [newMessage, setNewMessage] = useState('');

  // For auto-focus on the input
  const chatInputRef = useRef(null);

  // Simple unread count
  const unreadCount = !isOpen ? messages.length : 0;

  /** ------------------------------------------------------------------
   *  4) HANDLERS & EFFECTS
   * ------------------------------------------------------------------ */
  const handleToggleChat = useCallback(() => {
    setIsOpen((prev) => !prev);
    setIsMinimized(false);

    if (isOpen) {
      // If closing, reset to Home
      setView('HOME');
      setActiveConvDocId(null);
      setActiveConvData(null);
    }
  }, [isOpen]);

  const handleMinimizeChat = useCallback(() => {
    setIsMinimized((prev) => !prev);
  }, []);

  // Validate guest email
  const handleSaveGuestWrapper = useCallback(() => {
    const success = handleSaveGuestInfo();
    if (!success) {
      setMessagesError('Please enter a valid email address.');
    } else {
      setMessagesError(null);
    }
  }, [handleSaveGuestInfo, setMessagesError]);

  // Send a new message
  const handleSendMessage = useCallback(() => {
    if (!newMessage.trim()) return;
    sendMessage(newMessage);
    setNewMessage('');
  }, [sendMessage, newMessage]);

  // Focus input when widget is opened & not minimized
  useEffect(() => {
    if (isOpen && !isMinimized && chatInputRef.current) {
      setTimeout(() => {
        chatInputRef.current.querySelector('input')?.focus();
      }, 200);
    }
  }, [isOpen, isMinimized]);

  // Selecting a conversation from the home screen
  const handleSelectConversationDoc = useCallback((convDoc) => {
    setActiveConvDocId(convDoc.id);
    setActiveConvData(convDoc);
    setView('CHAT');
  }, []);

  // Start a new conversation
  const handleStartNewConversation = useCallback(async () => {
    try {
      await createNewConversation();
      // The snapshot will update. Optionally auto-select the new doc.
    } catch (err) {
      console.error('Error starting new convo:', err);
      setMessagesError('Could not start a new conversation.');
    }
  }, [createNewConversation, setMessagesError]);

  // Once conversationsList changes, optionally auto-select the newest
  useEffect(() => {
    if (!activeConvDocId && conversationsList.length > 0) {
      // You could automatically select the newest conversation if desired
    }
  }, [conversationsList, activeConvDocId]);

  // Watch changes to activeConvDocId to update the doc data
  useEffect(() => {
    if (!activeConvDocId) {
      setActiveConvData(null);
      return;
    }
    const found = conversationsList.find((c) => c.id === activeConvDocId);
    if (found) {
      setActiveConvData(found);
    }
  }, [activeConvDocId, conversationsList]);

  /** ------------------------------------------------------------------
   *  5) REOPEN A CLOSED CONVERSATION (Optional)
   * ------------------------------------------------------------------ */
  const handleReopenConversation = useCallback(() => {
    // If you have a method in Firestore that sets "isClosed" to false,
    // you'd do that here. For example:
    //
    // updateDoc(doc(db, 'conversations', activeConvDocId), {
    //   isClosed: false,
    // });
    //
    // Also could update all messages to `closed: false` if needed.
    // For demonstration, let's just pretend:
    alert('Reopening conversation... (demo only)');
  }, [activeConvDocId]);

  /** ------------------------------------------------------------------
   *  6) RENDER MESSAGE BUBBLE
   * ------------------------------------------------------------------ */
  const renderMessageBubble = useCallback(
    (msg) => {
      const isFromSelf = msg.senderId === conversationId;
      const isStaffReply =
        msg.senderRole === 'admin' || msg.senderRole === 'staff';
      const isSystemReply = msg.senderRole === 'system';

      // Chat bubble color logic
      let bubbleColor = 'grey.300';
      let textColor = '#000';

      if (isStaffReply) {
        bubbleColor = 'secondary.light';
        textColor = '#fff';
      } else if (isSystemReply) {
        bubbleColor = 'info.light';
        textColor = '#fff';
      } else if (isFromSelf) {
        bubbleColor = 'primary.light';
        textColor = '#fff';
      }

      // Format timestamp
      let timeStr = '';
      if (msg.createdAt?.toDate) {
        const dateObj = msg.createdAt.toDate();
        timeStr = dateObj.toLocaleString([], {
          hour: '2-digit',
          minute: '2-digit',
          month: 'numeric',
          day: 'numeric',
        });
      }

      return (
        <Box
          key={msg.id}
          sx={{
            display: 'flex',
            flexDirection: isFromSelf ? 'row-reverse' : 'row',
            mb: 2,
            gap: 1,
            alignItems: 'flex-end',
          }}
        >
          {/* Show an avatar for staff or system messages */}
          {(isStaffReply || isSystemReply) && (
            <Avatar
              alt={msg.senderName}
              sx={{
                width: 32,
                height: 32,
                bgcolor: isStaffReply ? 'secondary.main' : 'info.main',
              }}
            >
              {isStaffReply ? 'S' : 'BOT'}
            </Avatar>
          )}

          <Box
            sx={{
              maxWidth: '65%',
              p: 1.5,
              borderRadius: 2,
              backgroundColor: bubbleColor,
              color: textColor,
              boxShadow: 1,
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 0.5 }}>
              {msg.senderName}
            </Typography>
            <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
              {msg.text}
            </Typography>
            {timeStr && (
              <Typography
                variant="caption"
                sx={{ mt: 0.5, display: 'block', opacity: 0.75 }}
              >
                {timeStr}
              </Typography>
            )}
          </Box>
        </Box>
      );
    },
    [conversationId]
  );

  /** ------------------------------------------------------------------
   *  7) HOME SCREEN (LIST + SEARCH)
   * ------------------------------------------------------------------ */
  const [searchTerm, setSearchTerm] = useState('');

  // Filter conversations by category or last message snippet
  const filteredConversations = conversationsList.filter((conv) => {
    const lcSearch = searchTerm.toLowerCase();

    // If user typed nothing, show all
    if (!lcSearch) return true;

    const categoryStr = (conv.category || '').toLowerCase();
    // OPTIONAL: If you store a last message snippet, filter by that:
    // For demonstration, assume conv.lastMessageSnippet
    const snippetStr = (conv.lastMessageSnippet || '').toLowerCase();

    return categoryStr.includes(lcSearch) || snippetStr.includes(lcSearch);
  });

  const renderHomeScreen = () => (
    <Box sx={{ p: 2, flex: 1, display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
        Your Conversations
      </Typography>
      <Divider sx={{ mb: 2 }} />

      {/* Search Field */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 1 }}>
        <SearchIcon fontSize="small" sx={{ color: 'text.secondary' }} />
        <TextField
          size="small"
          label="Search by category..."
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Box>

      {/* List of conversations */}
      {loadingConversations ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <CircularProgress size={24} />
        </Box>
      ) : errorConversations ? (
        <Typography color="error">{errorConversations}</Typography>
      ) : filteredConversations.length === 0 ? (
        <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
          No matching conversations. Click “Start New Conversation” below.
        </Typography>
      ) : (
        <List dense sx={{ flex: 1, overflowY: 'auto' }}>
          {filteredConversations.map((conv) => {
            const dateStr = conv.createdAt?.toDate
              ? conv.createdAt
                  .toDate()
                  .toLocaleString([], { dateStyle: 'short', timeStyle: 'short' })
              : 'N/A';
            // If your docs track "isClosed" or similar, show a chip:
            const isClosed = conv.isClosed; // or your actual field

            return (
              <ListItem
                key={conv.id}
                disablePadding
                secondaryAction={
                  isClosed ? (
                    <Chip
                      label="Closed"
                      size="small"
                      color="error"
                      variant="outlined"
                      sx={{ mr: 1 }}
                    />
                  ) : (
                    <Chip
                      label="Open"
                      size="small"
                      color="success"
                      variant="outlined"
                      sx={{ mr: 1 }}
                    />
                  )
                }
              >
                <ListItemButton onClick={() => handleSelectConversationDoc(conv)}>
                  <ListItemText
                    primary={
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <ChatBubbleOutlineIcon sx={{ mr: 1 }} fontSize="small" />
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                          {conv.category || 'No Category'}
                        </Typography>
                      </Box>
                    }
                    secondary={dateStr}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      )}

      <Divider sx={{ my: 2 }} />
      <Box sx={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          startIcon={<ChatIcon />}
          onClick={handleStartNewConversation}
        >
          Start New Conversation
        </Button>
      </Box>
    </Box>
  );

  /** ------------------------------------------------------------------
   *  8) CHAT SCREEN
   * ------------------------------------------------------------------ */
  // We'll add a "scroll to bottom" button if user is scrolled up
  const [showScrollDown, setShowScrollDown] = useState(false);

  const handleChatScroll = useCallback((e) => {
    const container = e.target;
    // If near the bottom, hide the button
    const threshold = 100; // px from bottom
    const distanceFromBottom =
      container.scrollHeight - container.scrollTop - container.clientHeight;
    setShowScrollDown(distanceFromBottom > threshold);
  }, []);

  const scrollToBottom = useCallback(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messagesEndRef]);

  const renderChatScreen = () => {
    if (!activeConvData) {
      return (
        <Box sx={{ p: 2 }}>
          <Typography variant="body2">Loading conversation...</Typography>
        </Box>
      );
    }

    const isClosed = activeConvData.isClosed || isConversationClosed; // or however you track it

    return (
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        {/* Category Prompt if not set */}
        {!conversationCategory && (
          <Box
            sx={{
              backgroundColor: '#f9f9f9',
              p: 2,
              m: 2,
              borderRadius: 1,
              border: '1px solid #ccc',
              textAlign: 'center',
            }}
          >
            <Typography variant="body1" sx={{ mb: 2, fontWeight: 'bold' }}>
              How can we assist you today?
            </Typography>
            <Button
              variant="outlined"
              size="small"
              sx={{ mb: 1 }}
              fullWidth
              onClick={() => handleSelectCategory('Chat with Sales')}
            >
              Chat with Sales
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={{ mb: 1 }}
              fullWidth
              onClick={() => handleSelectCategory('Schedule a Demo')}
            >
              Schedule a Demo
            </Button>
            <Button
              variant="outlined"
              size="small"
              fullWidth
              onClick={() => handleSelectCategory("I'm looking for Support")}
            >
              I’m looking for Customer Support
            </Button>
          </Box>
        )}

        {/* If user is guest + no email => prompt */}
        {!currentUser?.uid && !guestEmail && (
          <Box
            ref={chatInputRef}
            sx={{
              backgroundColor: '#f9f9f9',
              p: 2,
              mx: 2,
              mb: 2,
              borderRadius: 1,
              border: '1px solid #ccc',
            }}
          >
            <Typography variant="body2" sx={{ mb: 1 }}>
              Please let us know how to reach you:
            </Typography>
            <TextField
              size="small"
              fullWidth
              type="email"
              label="Your Email (required)"
              aria-label="Enter your email"
              variant="outlined"
              value={emailInput}
              onChange={(e) => setEmailInput(e.target.value)}
              sx={{ mb: 1 }}
            />
            <TextField
              size="small"
              fullWidth
              label="Your Name (optional)"
              aria-label="Enter your name (optional)"
              variant="outlined"
              value={nameInput}
              onChange={(e) => setNameInput(e.target.value)}
              sx={{ mb: 1 }}
            />
            <Button
              variant="contained"
              onClick={handleSaveGuestWrapper}
              size="small"
            >
              Save
            </Button>
          </Box>
        )}

        {/* Messages List */}
        <Box
          sx={{
            flex: 1,
            overflowY: 'auto',
            border: '1px solid #ccc',
            borderRadius: 1,
            p: 1,
            mx: 2,
            mb: 1,
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          }}
          onScroll={handleChatScroll}
        >
          {messagesLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <CircularProgress size={24} />
            </Box>
          ) : messagesError ? (
            <Typography color="error">{messagesError}</Typography>
          ) : messages.length === 0 ? (
            <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
              No messages yet. Drop us a note!
            </Typography>
          ) : (
            messages.map(renderMessageBubble)
          )}
          {/* Scroll target */}
          <div ref={messagesEndRef} />
          {/* Scroll Down Button */}
          {showScrollDown && (
            <Button
              variant="contained"
              size="small"
              sx={{ position: 'absolute', bottom: 8, right: 8 }}
              onClick={scrollToBottom}
            >
              Scroll to bottom
            </Button>
          )}
        </Box>

        {/* If chat is closed => show notice + reopen option */}
        {isClosed && (
          <Box sx={{ px: 2, mb: 1, display: 'flex', alignItems: 'center' }}>
            <ErrorOutlineIcon fontSize="small" sx={{ mr: 1 }} color="error" />
            <Typography variant="body2" color="error" sx={{ mr: 2 }}>
              This chat is closed. You can’t send more messages.
            </Typography>
            {/* A Reopen Chat button if you want to allow reopening */}
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              onClick={handleReopenConversation}
            >
              Reopen Chat
            </Button>
          </Box>
        )}

        {/* Chat Input (hidden if closed, unless you handle re-open) */}
        {!isClosed && (
          <Box ref={chatInputRef} sx={{ px: 2, pb: 2 }}>
            <ChatInputBox
              newMessage={newMessage}
              setNewMessage={setNewMessage}
              currentUser={currentUser}
              guestEmail={guestEmail}
              isConversationClosed={isClosed}
              handleSendMessage={handleSendMessage}
            />
          </Box>
        )}

        {/* "Powered by" or disclaimers */}
        <Typography
          variant="caption"
          sx={{
            color: 'text.disabled',
            display: 'block',
            textAlign: 'center',
            fontSize: '0.7rem',
            mb: 1,
          }}
        >
          Powered by RCF Orthinas
        </Typography>
      </Box>
    );
  };

  /** ------------------------------------------------------------------
   *  9) MAIN WIDGET RENDER
   * ------------------------------------------------------------------ */
  // If staff/admin => hide completely
  if (isStaffOrAdmin) return null;

  return (
    <Box sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 9999 }}>
      {/* Floating Button if not open */}
      {!isOpen && (
        <Tooltip title="Chat with us!">
          <Badge
            badgeContent={unreadCount}
            color="error"
            overlap="circular"
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <IconButton
              onClick={handleToggleChat}
              sx={{
                backgroundColor: 'white',
                color: 'primary.main',
                borderRadius: '40px',
                width: 60,
                height: 60,
                '&:hover': {
                  backgroundColor: 'primary.light',
                  color: '#fff',
                },
              }}
              aria-label="Open chat"
            >
              <ChatIcon />
            </IconButton>
          </Badge>
        </Tooltip>
      )}

      {/* Chat box if open */}
      {isOpen && (
        <Paper
          elevation={6}
          sx={{
            width: 360,
            height: isMinimized ? 70 : 540,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 2,
            overflow: 'hidden',
            transition: 'height 0.3s ease',
          }}
        >
          {/* HEADER */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: 1,
              backgroundColor: 'grey.100',
              borderBottom: '1px solid #ccc',
            }}
          >
            <Box>
              {view === 'HOME' ? (
                <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                  Your Chats
                </Typography>
              ) : (
                <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                  Live Support
                  {conversationCategory && (
                    <Typography
                      component="span"
                      variant="caption"
                      sx={{ color: 'text.secondary', ml: 1 }}
                    >
                      ({conversationCategory})
                    </Typography>
                  )}
                </Typography>
              )}

              {/* Show email if known and not minimized */}
              {guestEmail && !isMinimized && view === 'CHAT' && (
                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                  You: {guestEmail}
                </Typography>
              )}
            </Box>

            <Box>
              {/* If in CHAT mode, show "Home" button */}
              {view === 'CHAT' && !isMinimized && (
                <Tooltip title="Back to your chat list">
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      setView('HOME');
                      setActiveConvDocId(null);
                      setActiveConvData(null);
                    }}
                    sx={{ mr: 1 }}
                  >
                    Home
                  </Button>
                </Tooltip>
              )}

              {/* Minimize or restore */}
              {!isMinimized && (
                <Tooltip title="Minimize chat widget">
                  <IconButton
                    size="small"
                    onClick={handleMinimizeChat}
                    sx={{ mr: 1 }}
                    aria-label="Minimize chat"
                  >
                    <MinimizeIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              )}
              {isMinimized && (
                <Tooltip title="Restore chat widget">
                  <IconButton
                    size="small"
                    onClick={handleMinimizeChat}
                    sx={{ mr: 1 }}
                    aria-label="Restore chat"
                  >
                    <ChatIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              )}

              {/* Close */}
              <Tooltip title="Close chat widget">
                <IconButton
                  size="small"
                  onClick={handleToggleChat}
                  aria-label="Close chat"
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>

          {/* If minimized, hide main content */}
          {!isMinimized && (
            <>
              {view === 'HOME' ? renderHomeScreen() : renderChatScreen()}
            </>
          )}
        </Paper>
      )}
    </Box>
  );
}
