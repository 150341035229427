// File: src/components/Navbar/BigSolutionsMegaMenu.jsx

import React, { useState, useMemo, useEffect } from 'react';
import {
  Box,
  Menu,
  Fade,
  Grid,
  Typography,
  Button,
  ButtonGroup,
  Grow,
  Divider,
  TextField,
  InputAdornment,
  CircularProgress,
  Alert,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '/Users/willradley/orthinas-crm/src/firebase.js'; // adjust as needed
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import GroupIcon from '@mui/icons-material/Group';
import BusinessIcon from '@mui/icons-material/Business';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import SearchIcon from '@mui/icons-material/Search';

/** Simple card component with a Grow transition. */
function SolutionCard({ title, description, slug, onClose, index }) {
  return (
    <Grow
      in
      style={{ transformOrigin: '0 0 0' }}
      timeout={500 + index * 100}
    >
      <Box
        component={Link}
        to={`/solutions/${slug}`} // dynamic link
        onClick={onClose}
        sx={{
          textDecoration: 'none',
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 1,
          p: 2,
          display: 'block',
          color: 'text.primary',
          transition: 'border-color 0.2s, box-shadow 0.2s',
          backgroundColor: 'background.paper',
          '&:hover': {
            borderColor: 'primary.main',
            boxShadow: 4,
          },
        }}
      >
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
        <Typography variant="body2" sx={{ mt: 0.5, color: 'text.secondary' }}>
          {description}
        </Typography>
      </Box>
    </Grow>
  );
}

/** Helper to filter by search term (title/description). */
function filterSolutions(items, query) {
  if (!query.trim()) return items;
  const lowerQuery = query.toLowerCase();
  return items.filter(
    (item) =>
      item.title.toLowerCase().includes(lowerQuery) ||
      item.description.toLowerCase().includes(lowerQuery)
  );
}

// Tab definitions by "type" in Firestore
const SOLUTION_TABS = [
  {
    label: 'Team',
    value: 'team',
    icon: <GroupIcon fontSize="small" />,
  },
  {
    label: 'Company Type',
    value: 'company',
    icon: <BusinessIcon fontSize="small" />,
  },
  {
    label: 'Templates',
    value: 'templates',
    icon: <AutoAwesomeMosaicIcon fontSize="small" />,
  },
];

export default function BigSolutionsMegaMenu({ anchorEl, open, onClose }) {
  const [activeTab, setActiveTab] = useState('team');
  const [searchTerm, setSearchTerm] = useState('');
  const [solutions, setSolutions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch solutions from Firestore once (or each time the menu is opened, if you prefer).
  useEffect(() => {
    async function fetchSolutions() {
      setLoading(true);
      setError(null);
      try {
        const snap = await getDocs(collection(db, 'solutions'));
        const loaded = [];
        snap.forEach((doc) => {
          loaded.push({
            id: doc.id, // doc ID will be used as the slug
            slug: doc.id,
            ...doc.data(),
          });
        });
        setSolutions(loaded);
      } catch (err) {
        console.error('Error fetching solutions:', err);
        setError('Unable to load solutions. Please try again later.');
      } finally {
        setLoading(false);
      }
    }

    if (open) {
      fetchSolutions();
    }
  }, [open]);

  // Filter solutions by the active tab
  const tabFilteredSolutions = useMemo(() => {
    // The doc must have a field "type" that matches the tab value: "team", "company", or "templates"
    return solutions.filter((s) => s.type === activeTab);
  }, [solutions, activeTab]);

  // Then filter by search
  const displayedSolutions = useMemo(() => {
    return filterSolutions(tabFilteredSolutions, searchTerm);
  }, [tabFilteredSolutions, searchTerm]);

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      TransitionComponent={Fade}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      PaperProps={{
        sx: {
          width: '900px',
          maxWidth: '95vw',
          p: 0,
          borderRadius: 3,
          boxShadow: 8,
          maxHeight: '75vh',
          overflowY: 'auto',
        },
      }}
    >
      <Box
        sx={{
          p: 3,
          // Subtle gradient background (optional)
          background: (theme) =>
            `linear-gradient(120deg, ${theme.palette.grey[50]} 0%, ${theme.palette.grey[100]} 100%)`,
        }}
      >
        {/* Top row: Search bar (left) + Tabs (right) */}
        <Box
          mb={3}
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          alignItems={{ xs: 'stretch', md: 'center' }}
          justifyContent="space-between"
          gap={2}
        >
          {/* Search bar */}
          <TextField
            size="small"
            placeholder="Search solutions..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
            sx={{
              flexGrow: 1,
              backgroundColor: (theme) => theme.palette.grey[50],
              boxShadow: 2,
              borderRadius: 1,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="disabled" />
                </InputAdornment>
              ),
            }}
          />

          {/* Tab Buttons */}
          <ButtonGroup
            variant="contained"
            sx={{
              backgroundColor: 'background.paper',
              boxShadow: 3,
              borderRadius: 2,
              flexShrink: 0,
            }}
          >
            {SOLUTION_TABS.map((tab) => {
              const isActive = activeTab === tab.value;
              return (
                <Button
                  key={tab.value}
                  onClick={() => setActiveTab(tab.value)}
                  startIcon={tab.icon}
                  sx={{
                    textTransform: 'none',
                    fontWeight: isActive ? 'bold' : 'medium',
                    color: isActive ? 'white' : 'text.primary',
                    backgroundColor: isActive ? 'primary.main' : 'inherit',
                    '&:hover': {
                      backgroundColor: isActive
                        ? 'primary.dark'
                        : 'grey.200',
                    },
                  }}
                >
                  {tab.label}
                </Button>
              );
            })}
          </ButtonGroup>
        </Box>

        {/* Loading / Error States */}
        {loading && (
          <Box textAlign="center" py={3}>
            <CircularProgress />
          </Box>
        )}
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {!loading && !error && (
          <>
            {/* Content area for the active tab (with Fade transition) */}
            <Fade in={!loading} timeout={350}>
              <Box>
                <Grid container spacing={2}>
                  {displayedSolutions.map((item, idx) => (
                    <Grid key={item.id} item xs={12} sm={6} md={4}>
                      <SolutionCard
                        title={item.title}
                        description={item.description}
                        slug={item.slug}
                        onClose={onClose}
                        index={idx}
                      />
                    </Grid>
                  ))}

                  {/* No results */}
                  {displayedSolutions.length === 0 && (
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        textAlign="center"
                        sx={{ py: 2 }}
                      >
                        No matching solutions found.
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Fade>
          </>
        )}

        {/* Divider & CTA at the bottom */}
        <Divider sx={{ mt: 4, mb: 3 }} />
        <Box display="flex" justifyContent="center" gap={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={onClose}
            component={Link}
            to="/pricing"
            endIcon={<ArrowForwardIcon />}
          >
            Start Free Trial
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={onClose}
            component={Link}
            to="/contact-sales"
            endIcon={<ArrowForwardIcon />}
          >
            Contact Sales
          </Button>
        </Box>
      </Box>
    </Menu>
  );
}
