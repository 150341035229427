// File: src/context/AuthContext.js
import React, { createContext, useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, onSnapshot } from 'firebase/firestore';
import { auth, db } from '../firebase';

// (Optional) MUI or custom spinner
import { CircularProgress } from '@mui/material';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  // We'll track the user doc separately
  const [userProfile, setUserProfile] = useState(null);

  // Separate loading states:
  // - authLoading: while waiting for onAuthStateChanged
  // - profileLoading: once we know the user, listening to Firestore
  const [authLoading, setAuthLoading] = useState(true);
  const [profileLoading, setProfileLoading] = useState(false);

  const [error, setError] = useState('');

  useEffect(() => {
    const unsubAuth = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setAuthLoading(false);

      // Clean up any previous Firestore subscription
      setUserProfile(null);

      if (user) {
        // If logged in, set up real-time listener to the Firestore doc
        setProfileLoading(true);
        const docRef = doc(db, 'users', user.uid);
        const unsubDoc = onSnapshot(
          docRef,
          (snap) => {
            if (snap.exists()) {
              setUserProfile(snap.data());
            } else {
              setUserProfile(null);
            }
            setProfileLoading(false);
          },
          (err) => {
            // Handle Firestore errors
            console.error('Error fetching user doc:', err);
            setError('Error loading user profile');
            setProfileLoading(false);
          }
        );

        // Cleanup Firestore listener when user logs out or component unmounts
        return () => unsubDoc();
      } else {
        // Not logged in → reset
        setUserProfile(null);
      }
    });

    // Cleanup the Auth listener on unmount
    return () => unsubAuth();
  }, []);

  // Overall loading: if auth is still loading OR profile is still loading
  const overallLoading = authLoading || profileLoading;

  if (overallLoading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '2rem' }}>
        <CircularProgress aria-label="Loading user/auth data" />
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ color: 'red', textAlign: 'center', marginTop: '2rem' }}>
        {error}
      </div>
    );
  }

  return (
    <AuthContext.Provider value={{ currentUser, userProfile }}>
      {children}
    </AuthContext.Provider>
  );
};
