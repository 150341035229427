// src/components/MultiSectionSubNav.jsx

import React, { useState, useMemo } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  styled,
  Stack,
  Typography,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  useMediaQuery,
  Fade,
  Divider,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

// Icons
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';

// Your custom function
import { getSectionConfig } from '../utils/sectionUtils';

// Constants
const SUB_NAVBAR_HEIGHT = 65;

const SectionAppBar = styled(AppBar)(({ theme }) => ({
  top: 64,
  height: SUB_NAVBAR_HEIGHT,
  boxShadow: '0 2px 5px rgba(0,0,0,0.4)',
  borderRadius: 0,
  zIndex: theme.zIndex.drawer + 1,
  transition: 'background-color 0.3s ease',
}));

export default function MultiSectionSubNav() {
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  /////////////////////////////////////////////////////
  // 1) Declare all Hooks unconditionally
  /////////////////////////////////////////////////////
  const [anchorEl, setAnchorEl] = useState(null);
  const mobileMenuOpen = Boolean(anchorEl);

  const handleMobileMenuOpen = (e) => setAnchorEl(e.currentTarget);
  const handleMobileMenuClose = () => setAnchorEl(null);

  // 2) Get the section config, but do NOT do an early return yet
  const section = getSectionConfig(location.pathname);

  // 3) Provide fallback nav items if section is null, 
  //    so we can still call useMemo unconditionally.
  const fallbackNavItems = section?.navItems || [];

  // 4) useMemo referencing fallbackNavItems
  const mobileItems = useMemo(() => {
    return [
      ...fallbackNavItems,
      {
        label: 'Settings',
        path: '/settings',
        icon: <SettingsIcon fontSize="small" />,
      },
    ];
  }, [fallbackNavItems]);

  // For desktop: If section is defined, do "more items" logic
  const maxDesktopItems = 4;
  const shouldShowMore = fallbackNavItems.length > maxDesktopItems;
  const mainNavItems = shouldShowMore
    ? fallbackNavItems.slice(0, maxDesktopItems)
    : fallbackNavItems;
  const moreNavItems = shouldShowMore
    ? fallbackNavItems.slice(maxDesktopItems)
    : [];

  // If no section, we can safely return null AFTER the hooks
  if (!section) {
    return null;
  }

  // Dynamic style from the actual section config
  const dynamicStyle = {
    background: section.background,
  };

  /////////////////////////////////////////////////////
  // 5) Render
  /////////////////////////////////////////////////////
  return (
    <SectionAppBar position="fixed" elevation={3} sx={dynamicStyle}>
      <Toolbar
        sx={{
          minHeight: SUB_NAVBAR_HEIGHT,
          px: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {/* LEFT: Section Icon + Label */}
        <Stack direction="row" alignItems="center" spacing={1}>
          {section.icon}
          <Typography
            variant="h6"
            sx={{ color: '#fff', fontWeight: 'bold', letterSpacing: '0.5px' }}
          >
            {section.label}
          </Typography>
        </Stack>

        {/* RIGHT: Mobile or Desktop */}
        {isMobile ? (
          // MOBILE LAYOUT
          <>
            <IconButton
              sx={{ color: '#fff' }}
              onClick={handleMobileMenuOpen}
              aria-label="Open sub-navigation menu"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={mobileMenuOpen}
              onClose={handleMobileMenuClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              TransitionComponent={Fade}
            >
              <MenuList dense>
                {mobileItems.map((item) => {
                  const isActive = location.pathname.startsWith(item.path);
                  return (
                    <MenuItem
                      key={item.path}
                      component={Link}
                      to={item.path}
                      onClick={handleMobileMenuClose}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        fontWeight: isActive ? 'bold' : 'medium',
                        px: 2,
                        py: 1,
                      }}
                    >
                      {item.icon}
                      {item.label}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Menu>
          </>
        ) : (
          // DESKTOP LAYOUT
          <DesktopNav
            mainNavItems={mainNavItems}
            moreNavItems={moreNavItems}
            locationPath={location.pathname}
          />
        )}
      </Toolbar>
    </SectionAppBar>
  );
}

/**
 * DesktopNav subcomponent
 */
function DesktopNav({ mainNavItems, moreNavItems, locationPath }) {
  const isActive = (path) => locationPath.startsWith(path);

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      {/* Main items */}
      <Stack direction="row" spacing={2} alignItems="center">
        {mainNavItems.map((link) => (
          <Tooltip key={link.path} title={`Go to ${link.label}`} arrow>
            <Button
              component={Link}
              to={link.path}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 0.75,
                color: '#fff',
                textTransform: 'none',
                fontSize: '0.9rem',
                fontWeight: isActive(link.path) ? 'bold' : 'medium',
                px: 1.5,
                py: 0.75,
                borderRadius: 1,
                transition: 'background-color 0.2s ease',
                ...(isActive(link.path) && {
                  backgroundColor: 'rgba(255,255,255,0.2)',
                }),
                '&:hover': {
                  backgroundColor: 'rgba(255,255,255,0.3)',
                },
              }}
            >
              {link.icon}
              {link.label}
            </Button>
          </Tooltip>
        ))}
      </Stack>

      {/* Optionally render MoreMenu if needed */}
      {moreNavItems.length > 0 && (
        <MoreMenu items={moreNavItems} locationPath={locationPath} />
      )}

      <Divider orientation="vertical" flexItem sx={{ borderColor: 'rgba(255,255,255,0.3)' }} />

      {/* Settings */}
      <Tooltip title="Go to Settings">
        <Button
          component={Link}
          to="/settings"
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
            color: '#fff',
            textTransform: 'none',
            fontSize: '0.9rem',
            fontWeight: 500,
            px: 1.5,
            py: 0.75,
            borderRadius: 1,
            '&:hover': {
              backgroundColor: 'rgba(255,255,255,0.3)',
            },
          }}
        >
          <SettingsIcon fontSize="small" />
          Settings
        </Button>
      </Tooltip>
    </Stack>
  );
}

/**
 * MoreMenu subcomponent
 */
function MoreMenu({ items, locationPath }) {
  const [menuAnchor, setMenuAnchor] = useState(null);
  const open = Boolean(menuAnchor);

  const handleOpen = (e) => setMenuAnchor(e.currentTarget);
  const handleClose = () => setMenuAnchor(null);

  const isActive = (path) => locationPath.startsWith(path);

  if (items.length === 0) return null;

  return (
    <>
      <Tooltip title="More Options" arrow>
        <Button
          onClick={handleOpen}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
            color: '#fff',
            textTransform: 'none',
            fontSize: '0.9rem',
            fontWeight: 500,
            px: 1.5,
            py: 0.75,
            borderRadius: 1,
            '&:hover': {
              backgroundColor: 'rgba(255,255,255,0.3)',
            },
          }}
        >
          More
        </Button>
      </Tooltip>
      <Menu
        anchorEl={menuAnchor}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <MenuList dense>
          {items.map((item) => (
            <MenuItem
              key={item.path}
              component={Link}
              to={item.path}
              onClick={handleClose}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                fontWeight: isActive(item.path) ? 'bold' : 'medium',
                px: 2,
                py: 1,
              }}
            >
              {item.icon}
              {item.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </>
  );
}
