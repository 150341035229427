// useConversationCategory.js

import { useState, useCallback } from 'react';
import { setDoc, doc, addDoc, collection, serverTimestamp } from 'firebase/firestore';

/**
 * Allows the user to pick a category (e.g., "sales", "demo", or "support"),
 * then saves it in "conversations" doc and adds a system message about it.
 */
export default function useConversationCategory(db, conversationId) {
  const [conversationCategory, setConversationCategory] = useState(null);

  const handleSelectCategory = useCallback(
    async (category) => {
      setConversationCategory(category);

      if (conversationId) {
        try {
          // 1) Create/update doc in the "conversations" collection with this category
          await setDoc(
            doc(db, 'conversations', conversationId),
            {
              category,
              conversationId,
              createdAt: serverTimestamp(),
            },
            { merge: true }
          );

          // 2) Optionally store a "system" message about it
          await addDoc(collection(db, 'messages'), {
            text: `User selected: ${category}. We'll connect you shortly!`,
            senderId: 'systemBot',
            senderName: 'System',
            senderRole: 'system',
            conversationId,
            createdAt: serverTimestamp(),
            closed: false,
          });
        } catch (err) {
          console.error('Error adding category to conversation:', err);
        }
      }
    },
    [db, conversationId]
  );

  return {
    conversationCategory,
    handleSelectCategory,
  };
}
