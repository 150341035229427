// src/context/CRMContext.js

import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback
} from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { AuthContext } from './AuthContext';

// Services for each entity
import {
  getAllContacts,
  addContact,
  updateContact,
  deleteContact
} from '../services/contacts';

import {
  getAllDeals,
  addDeal,
  updateDeal,
  deleteDeal
} from '../services/deals';

import {
  getAllCatalogItems,
  addCatalogItem,
  updateCatalogItem,
  deleteCatalogItem
} from '../services/catalogItems';

import {
  getAllMarketingCampaigns,
  addMarketingCampaign,
  updateMarketingCampaign,
  deleteMarketingCampaign
} from '../services/marketingCampaigns';

import {
  getAllTasks,
  addTask,
  updateTask,
  deleteTask
} from '../services/tasks';

import {
  getAllCases,
  addCaseToFirestore,
  updateCaseInFirestore,
  deleteCaseFromFirestore
} from '../services/cases';

import {
  getAllEvents,
  addEvent,
  updateEvent,
  deleteEvent
} from '../services/events';

import {
  getAllGoals,
  addGoal,
  updateGoal,
  deleteGoal
} from '../services/goals';

// --- Import the Knowledge Base service methods ---
import {
  getAllKnowledgeArticles,
  addKnowledgeArticle,
  editKnowledgeArticle,
  deleteKnowledgeArticle
} from '../services/knowledgeBase';

/**
 * The main CRM Context that provides all data and methods (tasks, events, goals, etc.).
 */
export const CRMContext = createContext();

export const CRMProvider = ({ children }) => {
  const { currentUser } = useContext(AuthContext);

  // -------------------------------
  // States for various CRM entities
  // -------------------------------
  const [contacts, setContacts] = useState([]);
  const [deals, setDeals] = useState([]);
  const [catalogItems, setCatalogItems] = useState([]);
  const [marketingCampaigns, setMarketingCampaigns] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [cases, setCases] = useState([]);
  const [events, setEvents] = useState([]);
  const [goals, setGoals] = useState([]);

  // **NEW**: Knowledge Articles state
  const [knowledgeArticles, setKnowledgeArticles] = useState([]);

  // Default currency stored in Firestore (if applicable)
  const [defaultCurrency, setDefaultCurrency] = useState('USD');

  // Control whether we've fetched the data yet
  const [initialized, setInitialized] = useState(false);

  /**
   * Fetch initial data from Firestore (or anywhere else) once the user is logged in.
   */
  const fetchInitialData = useCallback(async () => {
    if (!currentUser) return;

    try {
      // 1) Attempt to load defaultCurrency from userBusinesses doc
      const userBusinessRef = doc(db, 'userBusinesses', currentUser.uid);
      const userBusinessSnap = await getDoc(userBusinessRef);
      if (userBusinessSnap.exists()) {
        const bizData = userBusinessSnap.data();
        if (bizData.defaultCurrency) {
          setDefaultCurrency(bizData.defaultCurrency);
        }
      }
    } catch (err) {
      console.error('Error fetching user business doc:', err);
    }

    // 2) Load all CRM data in parallel
    try {
      const [
        contactsData,
        dealsData,
        catalogData,
        campaignsData,
        casesData,
        tasksData,
        eventsData,
        goalsData,
        articlesData
      ] = await Promise.all([
        getAllContacts(),
        getAllDeals(),
        getAllCatalogItems(),
        getAllMarketingCampaigns(),
        getAllCases(),
        getAllTasks(currentUser.uid), // tasks might be user-specific
        getAllEvents(),
        getAllGoals(),
        // **NEW**: fetch knowledge articles for this user
        getAllKnowledgeArticles(currentUser.uid)
      ]);

      // 3) Update states
      setContacts(contactsData);
      setDeals(dealsData);
      setCatalogItems(catalogData);
      setMarketingCampaigns(campaignsData);
      setCases(casesData);
      setTasks(tasksData);
      setEvents(eventsData);
      setGoals(goalsData);

      // **NEW**:
      setKnowledgeArticles(articlesData);

      setInitialized(true);
    } catch (err) {
      console.error('Error fetching initial CRM data:', err);
    }
  }, [currentUser]);

  /**
   * UseEffect: Fetch data on mount if user is logged in and data not yet initialized.
   */
  useEffect(() => {
    if (currentUser && !initialized) {
      fetchInitialData();
    }
  }, [currentUser, initialized, fetchInitialData]);

  /**
   * OPTIONAL UseEffect: If user logs out, clear the data so it doesn't persist for the next user.
   * (Remove if you prefer to keep data in memory, though that is usually insecure.)
   */
  useEffect(() => {
    if (!currentUser) {
      // Reset all states
      setContacts([]);
      setDeals([]);
      setCatalogItems([]);
      setMarketingCampaigns([]);
      setCases([]);
      setTasks([]);
      setEvents([]);
      setGoals([]);
      setKnowledgeArticles([]); // **NEW**
      setDefaultCurrency('USD');
      setInitialized(false);
    }
  }, [currentUser]);

  /* =========================== Refresh methods ============================= */
  const refreshContacts = useCallback(async () => {
    if (!currentUser) return;
    try {
      const data = await getAllContacts();
      setContacts(data);
    } catch (err) {
      console.error('Error refreshing contacts:', err);
    }
  }, [currentUser]);

  const refreshDeals = useCallback(async () => {
    if (!currentUser) return;
    try {
      const data = await getAllDeals();
      setDeals(data);
    } catch (err) {
      console.error('Error refreshing deals:', err);
    }
  }, [currentUser]);

  const refreshCatalogItems = useCallback(async () => {
    if (!currentUser) return;
    try {
      const data = await getAllCatalogItems();
      setCatalogItems(data);
    } catch (err) {
      console.error('Error refreshing catalog items:', err);
    }
  }, [currentUser]);

  const refreshMarketingCampaigns = useCallback(async () => {
    if (!currentUser) return;
    try {
      const data = await getAllMarketingCampaigns();
      setMarketingCampaigns(data);
    } catch (err) {
      console.error('Error refreshing marketing campaigns:', err);
    }
  }, [currentUser]);

  const refreshTasks = useCallback(async () => {
    if (!currentUser) return;
    try {
      const data = await getAllTasks(currentUser.uid);
      setTasks(data);
    } catch (err) {
      console.error('Error refreshing tasks:', err);
    }
  }, [currentUser]);

  const refreshCases = useCallback(async () => {
    if (!currentUser) return;
    try {
      const data = await getAllCases();
      setCases(data);
    } catch (err) {
      console.error('Error refreshing cases:', err);
    }
  }, [currentUser]);

  const refreshEvents = useCallback(async () => {
    if (!currentUser) return;
    try {
      const data = await getAllEvents();
      setEvents(data);
    } catch (err) {
      console.error('Error refreshing events:', err);
    }
  }, [currentUser]);

  const refreshGoals = useCallback(async () => {
    if (!currentUser) return;
    try {
      const data = await getAllGoals();
      setGoals(data);
    } catch (err) {
      console.error('Error refreshing goals:', err);
    }
  }, [currentUser]);

  // **NEW**: refresh knowledge articles
  const refreshKnowledgeArticles = useCallback(async () => {
    if (!currentUser) return;
    try {
      const articlesData = await getAllKnowledgeArticles(currentUser.uid);
      console.log('Fetched articles for user', currentUser.uid, articlesData);
      setKnowledgeArticles(articlesData);
    } catch (err) {
      console.error('Error refreshing knowledge articles:', err);
    }
  }, [currentUser]);

  /* =========================== CRUD: Contacts ============================== */
  async function addNewContact(contact) {
    if (!currentUser) return;
    const newContact = { ...contact, userId: currentUser.uid };
    await addContact(newContact);
    await refreshContacts();
  }

  async function editContact(contactId, updateData) {
    await updateContact(contactId, updateData);
    await refreshContacts();
  }

  async function removeContact(contactId) {
    await deleteContact(contactId);
    await refreshContacts();
  }

  /* =========================== CRUD: Cases ============================== */
  async function addNewCase(newCase) {
    if (!currentUser) return;
    await addCaseToFirestore({ ...newCase, userId: currentUser.uid });
    await refreshCases();
  }

  async function editCase(caseId, updateData) {
    await updateCaseInFirestore(caseId, updateData);
    await refreshCases();
  }

  async function removeCase(caseId) {
    await deleteCaseFromFirestore(caseId);
    await refreshCases();
  }

  /* =========================== CRUD: Deals ============================== */
  async function addNewDeal(deal) {
    if (!currentUser) return;
    await addDeal({ ...deal, userId: currentUser.uid });
    await refreshDeals();
  }

  async function editDeal(dealId, updateData) {
    await updateDeal(dealId, updateData);
    await refreshDeals();
  }

  async function removeDeal(dealId) {
    await deleteDeal(dealId);
    await refreshDeals();
  }

  // Linking deals & contacts (example)
  async function linkDealToContact(dealId, contactId) {
    await editDeal(dealId, { contactId });
  }
  async function unlinkDealFromContact(dealId) {
    await editDeal(dealId, { contactId: '' });
  }
  function getDealsByContactId(contactId) {
    return deals.filter((d) => d.contactId === contactId);
  }
  function getContactForDeal(dealId) {
    const deal = deals.find((d) => d.id === dealId);
    if (!deal || !deal.contactId) return null;
    return contacts.find((c) => c.id === deal.contactId) || null;
  }

  /* =========================== CRUD: Catalog Items ============================== */
  async function addNewCatalogItem(item) {
    if (!currentUser) return;
    await addCatalogItem({ ...item, userId: currentUser.uid });
    await refreshCatalogItems();
  }

  async function editCatalogItem(itemId, updateData) {
    await updateCatalogItem(itemId, updateData);
    await refreshCatalogItems();
  }

  async function removeCatalogItem(itemId) {
    await deleteCatalogItem(itemId);
    await refreshCatalogItems();
  }

  /* =========================== CRUD: Marketing ============================== */
  async function addNewMarketingCampaign(campaign) {
    if (!currentUser) return;
    await addMarketingCampaign({ ...campaign, userId: currentUser.uid });
    await refreshMarketingCampaigns();
  }

  async function editMarketingCampaign(campaignId, updateData) {
    await updateMarketingCampaign(campaignId, updateData);
    await refreshMarketingCampaigns();
  }

  async function removeMarketingCampaign(campaignId) {
    await deleteMarketingCampaign(campaignId);
    await refreshMarketingCampaigns();
  }

  /* =========================== CRUD: Tasks ============================== */
  async function addNewTask(task) {
    if (!currentUser) return;
    const newTask = {
      ...task,
      userId: currentUser.uid,
      completed: task.completed ?? false
    };
    await addTask(newTask);
    await refreshTasks();
  }

  async function editTask(taskId, updateData) {
    await updateTask(taskId, updateData);
    await refreshTasks();
  }

  async function removeTask(taskId) {
    await deleteTask(taskId);
    await refreshTasks();
  }

  /* =========================== CRUD: Events ============================== */
  async function addNewEvent(evt) {
    if (!currentUser) return;
    await addEvent({ ...evt, userId: currentUser.uid });
    await refreshEvents();
  }

  async function editEvent(eventId, updateData) {
    await updateEvent(eventId, updateData);
    await refreshEvents();
  }

  async function removeEvent(eventId) {
    await deleteEvent(eventId);
    await refreshEvents();
  }

  /* =========================== CRUD: Goals ============================== */
  async function addNewGoal(goal) {
    if (!currentUser) return;
    await addGoal({ ...goal, userId: currentUser.uid });
    await refreshGoals();
  }

  async function editGoal(goalId, updateData) {
    await updateGoal(goalId, updateData);
    await refreshGoals();
  }

  async function removeGoal(goalId) {
    await deleteGoal(goalId);
    await refreshGoals();
  }

  /* =========================== CRUD: Knowledge Articles ============================== */
  async function addNewKnowledgeArticle(article) {
    if (!currentUser) return;
    await addKnowledgeArticle({ ...article, userId: currentUser.uid });
    await refreshKnowledgeArticles();
  }

  async function editKnowledgeArticleInContext(articleId, updateFields) {
    await editKnowledgeArticle(articleId, updateFields);
    await refreshKnowledgeArticles();
  }

  async function removeKnowledgeArticle(articleId) {
    await deleteKnowledgeArticle(articleId);
    await refreshKnowledgeArticles();
  }

  // -------------------------------------
  // Provide everything via context
  // -------------------------------------
  return (
    <CRMContext.Provider
      value={{
        // States
        contacts,
        deals,
        catalogItems,
        marketingCampaigns,
        tasks,
        cases,
        events,
        goals,
        // **NEW** 
        knowledgeArticles,

        // Default currency
        defaultCurrency,

        // Contact actions
        addNewContact,
        editContact,
        removeContact,

        // Cases
        addNewCase,
        editCase,
        removeCase,

        // Deals
        addNewDeal,
        editDeal,
        removeDeal,
        linkDealToContact,
        unlinkDealFromContact,
        getDealsByContactId,
        getContactForDeal,

        // Catalog
        addNewCatalogItem,
        editCatalogItem,
        removeCatalogItem,

        // Marketing
        addNewMarketingCampaign,
        editMarketingCampaign,
        removeMarketingCampaign,

        // Tasks
        addNewTask,
        editTask,
        removeTask,

        // Events
        addNewEvent,
        editEvent,
        removeEvent,

        // Goals
        addNewGoal,
        editGoal,
        removeGoal,

        // **NEW** Knowledge Articles
        addNewKnowledgeArticle,
        editKnowledgeArticleInContext,
        removeKnowledgeArticle
      }}
    >
      {children}
    </CRMContext.Provider>
  );
};
