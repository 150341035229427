// File: src/components/Navbar/BigResourcesMegaMenu.jsx

import React, { useState, useMemo, useEffect } from 'react';
import {
  Box,
  Menu,
  Fade,
  Grid,
  Typography,
  Button,
  ButtonGroup,
  Grow,
  Divider,
  TextField,
  InputAdornment,
  CircularProgress,
  Alert,
  Link as MuiLink,
} from '@mui/material';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '/Users/willradley/orthinas-crm/src/firebase.js'; // adjust if needed
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SearchIcon from '@mui/icons-material/Search';
import BookIcon from '@mui/icons-material/Book';
import MapIcon from '@mui/icons-material/Map';
import BuildIcon from '@mui/icons-material/Build';
import { Link as RouterLink } from 'react-router-dom'; // if you need internal routing

/** 
 * 1) ResourceCard
 *    - Removed target="_blank" and rel="noopener noreferrer"
 *    - Now opens in the same tab by default
 */
function ResourceCard({ title, description, url, onClose, index }) {
  return (
    <Grow
      in
      style={{ transformOrigin: '0 0 0' }}
      timeout={500 + index * 100}
    >
      <Box
        // We use <a> if url is external, or <Link> for internal routes.
        component="a"
        href={url}
        onClick={onClose}
        // Removed target="_blank" so it opens in the same tab
        sx={{
          textDecoration: 'none',
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 1,
          p: 2,
          display: 'block',
          color: 'text.primary',
          transition: 'border-color 0.2s, box-shadow 0.2s',
          backgroundColor: 'background.paper',
          '&:hover': {
            borderColor: 'primary.main',
            boxShadow: 4,
          },
        }}
      >
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
        <Typography variant="body2" sx={{ mt: 0.5, color: 'text.secondary' }}>
          {description}
        </Typography>
      </Box>
    </Grow>
  );
}

/** 
 * 2) Filter function
 */
function filterResources(items, query) {
  if (!query.trim()) return items;
  const lowerQuery = query.toLowerCase();
  return items.filter(
    (item) =>
      item.title.toLowerCase().includes(lowerQuery) ||
      item.description.toLowerCase().includes(lowerQuery)
  );
}

/** 
 * 3) Resource tabs: "learn", "discover", "services"
 */
const RESOURCE_TABS = [
  {
    label: 'Learn',
    value: 'learn',
    icon: <BookIcon fontSize="small" />,
  },
  {
    label: 'Discover',
    value: 'discover',
    icon: <MapIcon fontSize="small" />,
  },
  {
    label: 'Services',
    value: 'services',
    icon: <BuildIcon fontSize="small" />,
  },
];

export default function BigResourcesMegaMenu({ anchorEl, open, onClose }) {
  const [activeTab, setActiveTab] = useState('learn');
  const [searchTerm, setSearchTerm] = useState('');
  const [resources, setResources] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // 4) Fetch from "resources" collection
  useEffect(() => {
    async function fetchResources() {
      setLoading(true);
      setError(null);
      try {
        const snap = await getDocs(collection(db, 'resources'));
        const loaded = snap.docs.map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data(),
        }));
        setResources(loaded);
      } catch (err) {
        console.error('Error fetching resources:', err);
        setError('Unable to load resources. Please try again later.');
      } finally {
        setLoading(false);
      }
    }

    if (open) {
      fetchResources();
    }
  }, [open]);

  // 5) Filter by tab
  const tabFilteredResources = useMemo(() => {
    return resources.filter((r) => r.category === activeTab);
  }, [resources, activeTab]);

  // 6) Filter by search
  const displayedResources = useMemo(() => {
    return filterResources(tabFilteredResources, searchTerm);
  }, [tabFilteredResources, searchTerm]);

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      TransitionComponent={Fade}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      PaperProps={{
        sx: {
          width: 900,
          maxWidth: '95vw',
          p: 0,
          borderRadius: 3,
          boxShadow: 8,
          maxHeight: '75vh',
          overflowY: 'auto',
        },
      }}
    >
      <Box
        sx={{
          p: 3,
          background: (theme) =>
            `linear-gradient(120deg, ${theme.palette.grey[50]} 0%, ${theme.palette.grey[100]} 100%)`,
        }}
      >
        {/* top row: search + tabs */}
        <Box
          mb={3}
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          alignItems={{ xs: 'stretch', md: 'center' }}
          justifyContent="space-between"
          gap={2}
        >
          {/* search */}
          <TextField
            size="small"
            placeholder="Search resources..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
            sx={{
              flexGrow: 1,
              backgroundColor: (theme) => theme.palette.grey[50],
              boxShadow: 2,
              borderRadius: 1,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="disabled" />
                </InputAdornment>
              ),
            }}
          />

          {/* tab group */}
          <ButtonGroup variant="contained" sx={{ borderRadius: 2 }}>
            {RESOURCE_TABS.map((tab) => {
              const isActive = activeTab === tab.value;
              return (
                <Button
                  key={tab.value}
                  onClick={() => setActiveTab(tab.value)}
                  startIcon={tab.icon}
                  sx={{
                    textTransform: 'none',
                    fontWeight: isActive ? 'bold' : 'medium',
                    color: isActive ? 'white' : 'text.primary',
                    backgroundColor: isActive ? 'primary.main' : 'inherit',
                    '&:hover': {
                      backgroundColor: isActive
                        ? 'primary.dark'
                        : 'grey.200',
                    },
                  }}
                >
                  {tab.label}
                </Button>
              );
            })}
          </ButtonGroup>
        </Box>

        {/* loading / error */}
        {loading && (
          <Box textAlign="center" py={3}>
            <CircularProgress />
          </Box>
        )}
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {!loading && !error && (
          <>
            {/* If none */}
            {displayedResources.length === 0 && (
              <Typography
                variant="body2"
                color="text.secondary"
                textAlign="center"
                sx={{ py: 2 }}
              >
                No matching resources found.
              </Typography>
            )}

            <Fade in={!loading} timeout={350}>
              <Box>
                <Grid container spacing={2}>
                  {displayedResources.map((res, idx) => (
                    <Grid key={res.id} item xs={12} sm={6} md={4}>
                      <ResourceCard
                        title={res.title}
                        description={res.description}
                        url={res.url}
                        onClose={onClose}
                        index={idx}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Fade>
          </>
        )}

        {/* CTA row */}
        <Divider sx={{ mt: 4, mb: 3 }} />
        <Box display="flex" justifyContent="center" gap={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={onClose}
            endIcon={<ArrowForwardIcon />}
            component={RouterLink} // or <MuiLink href="/pricing"> if purely external
            to="/pricing"
          >
            Start Free Trial
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={onClose}
            endIcon={<ArrowForwardIcon />}
            component={RouterLink}
            to="/contact-sales"
          >
            Contact Sales
          </Button>
        </Box>
      </Box>
    </Menu>
  );
}
