import React, { createContext, useState, useEffect, useMemo } from 'react';
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { deepmerge } from '@mui/utils';

// Create our context
export const ThemeContext = createContext();

/**
 * Optional helper to detect if localStorage is available.
 * Helps avoid errors in SSR or restrictive environments.
 */
const isLocalStorageAvailable = () => {
  try {
    const testKey = '__test__';
    localStorage.setItem(testKey, testKey);
    localStorage.removeItem(testKey);
    return true;
  } catch {
    return false;
  }
};

/**
 * A simple color contrast checker to choose between two text colors
 * based on the background luminance. You can expand to a more
 * robust WCAG checker if desired.
 */
function getContrastText(bgColor, lightText = '#ffffff', darkText = '#000000') {
  // quick approximate luminance check
  const color = bgColor.replace('#', '');
  const r = parseInt(color.substr(0, 2), 16) / 255;
  const g = parseInt(color.substr(2, 2), 16) / 255;
  const b = parseInt(color.substr(4, 2), 16) / 255;

  // approximate luminance
  const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
  return luminance > 0.5 ? darkText : lightText;
}

const ThemeProviderComponent = ({ children }) => {
  const storageAvailable = isLocalStorageAvailable();

  // 1) Detect system color scheme:
  const prefersDark = typeof window !== 'undefined'
    ? window.matchMedia('(prefers-color-scheme: dark)').matches
    : false;

  // 2) Load from localStorage if available, otherwise fallback to system or 'light'
  const savedTheme = storageAvailable
    ? localStorage.getItem('appTheme') || (prefersDark ? 'dark' : 'light')
    : (prefersDark ? 'dark' : 'light');

  const savedLargeText = storageAvailable
    ? localStorage.getItem('largeTextEnabled') === 'true'
    : false;

  const [mode, setMode] = useState(savedTheme); // 'light' | 'dark'
  const [largeTextEnabled, setLargeTextEnabled] = useState(savedLargeText);
  const [locale, setLocale] = useState('en-US'); // example: store user locale

  // 3) Watch for system preference changes in real-time (optional)
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleSystemChange = (e) => {
      // only update if user is in sync mode:
      if (!storageAvailable || !localStorage.getItem('appTheme')) {
        setMode(e.matches ? 'dark' : 'light');
      }
    };

    mediaQuery.addEventListener('change', handleSystemChange);
    return () => {
      mediaQuery.removeEventListener('change', handleSystemChange);
    };
  }, [storageAvailable]);

  // 4) Sync localStorage whenever mode or largeText changes
  useEffect(() => {
    if (storageAvailable) {
      localStorage.setItem('appTheme', mode);
      localStorage.setItem('largeTextEnabled', largeTextEnabled);
    }
  }, [mode, largeTextEnabled, storageAvailable]);

  // 5) Handlers
  const toggleTheme = () => {
    setMode((prev) => (prev === 'light' ? 'dark' : 'light'));
  };

  const toggleLargeText = () => {
    setLargeTextEnabled((prev) => !prev);
  };

  // 6) Brand / custom palette colors
  const primaryColor = '#0052cc';
  const secondaryColor = '#009688';
  const successColor = '#28a745';
  const warningColor = '#ff9800';
  const errorColor   = '#f44336';
  const infoColor    = '#2196f3';

  // extended greys
  const greyColor = {
    50:  '#fafafa',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
  };

  // 7) Additional toggles
  // Force main background white, but still allow differences for 'paper'
  const forceWhiteBackground = true;

  // 8) Typography base size
  const baseFontSize = largeTextEnabled ? 18 : 14;

  // 9) Build the MUI theme
  const theme = useMemo(() => {
    let newTheme = createTheme({
      // 9a) Palette setup
      palette: {
        mode,
        primary: {
          main: primaryColor,
          contrastText: getContrastText(primaryColor),
        },
        secondary: {
          main: secondaryColor,
          contrastText: getContrastText(secondaryColor),
        },
        success: {
          main: successColor,
          contrastText: getContrastText(successColor),
        },
        warning: {
          main: warningColor,
          contrastText: getContrastText(warningColor),
        },
        error: {
          main: errorColor,
          contrastText: getContrastText(errorColor),
        },
        info: {
          main: infoColor,
          contrastText: getContrastText(infoColor),
        },
        grey: greyColor,

        ...(forceWhiteBackground
          ? {
              background: {
                default: '#ffffff',
                // If you want paper to remain distinct, switch color or keep it white
                paper: '#f9f9f9'
              },
              text: {
                primary: '#333333',
                secondary: '#555555',
              },
            }
          : {
              background: {
                default: mode === 'dark' ? '#121212' : '#fafafa',
                paper: mode === 'dark' ? '#1f1f1f' : '#ffffff',
              },
              text: {
                primary: mode === 'dark' ? '#ffffff' : '#333333',
                secondary: mode === 'dark' ? '#cccccc' : '#555555',
              },
            }
        ),
      },

      // 9b) Shape
      shape: {
        borderRadius: 10,
      },

      // 9c) Breakpoints
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1536,
          // example of a custom breakpoint for "2xl" if needed
          // '2xl': 1920,
        },
      },

      // 9d) Typography
      typography: {
        fontSize: baseFontSize,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        // Weighted headings
        h1: { fontWeight: 700 },
        h2: { fontWeight: 700 },
        h3: { fontWeight: 600 },
        h4: { fontWeight: 600 },
        button: {
          textTransform: 'none',
          fontWeight: 500,
        },
      },

      // 9e) Components (Overrides)
      components: {
        MuiCssBaseline: {
          styleOverrides: {
            html: {
              scrollBehavior: 'smooth',
            },
            body: {
              transition: 'background-color 0.3s ease, color 0.3s ease',
              ...(forceWhiteBackground && {
                backgroundColor: '#ffffff',
              }),
            },
            a: {
              textDecoration: 'none',
              color: primaryColor,
              '&:hover': {
                textDecoration: 'underline',
              },
            },
          },
        },

        MuiButton: {
          styleOverrides: {
            root: {
              borderRadius: 8,
              transition: 'background-color 0.3s, color 0.3s',
              '@media (max-width:600px)': {
                padding: '8px 16px',
                fontSize: '0.875rem',
              },
            },
            containedPrimary: {
              '&:hover': {
                backgroundColor: '#003da5',
              },
            },
          },
        },

        MuiPaper: {
          styleOverrides: {
            root: {
              borderRadius: 12,
              ...(forceWhiteBackground && {
                backgroundColor: '#ffffff',
              }),
            },
          },
        },

        MuiTextField: {
          styleOverrides: {
            root: {
              marginTop: 8,
              marginBottom: 8,
              '@media (max-width:600px)': {
                marginTop: 4,
                marginBottom: 4,
              },
            },
          },
        },

        MuiDrawer: {
          styleOverrides: {
            paper: {
              '@media (max-width:600px)': {
                width: '75vw',
              },
            },
          },
        },

        MuiAppBar: {
          styleOverrides: {
            root: {
              '@media (max-width:600px)': {
                height: 56,
              },
              transition: 'height 0.3s ease',
            },
          },
        },

        // If you're using the MUI X DataGrid in CRM:
        // (uncomment if @mui/x-data-grid is installed)
        // MuiDataGrid: {
        //   styleOverrides: {
        //     root: {
        //       borderRadius: 8,
        //       border: '1px solid',
        //       borderColor: greyColor[300],
        //       '@media (max-width:600px)': {
        //         fontSize: '0.75rem',
        //       },
        //     },
        //   },
        // },

        // Optional: if using MUI x DatePickers
        // MuiDatePicker: {
        //   defaultProps: {
        //     // example: set a default locale or something
        //   },
        //   styleOverrides: {
        //     root: {
        //       // your overrides here
        //     },
        //   },
        // },

        // Optional: Table overrides (often used in CRMs)
        // MuiTable: {
        //   styleOverrides: {
        //     root: {
        //       borderCollapse: 'separate',
        //       borderSpacing: '0 8px',
        //       // ...
        //     },
        //   },
        // },
      },

      // Optional: pass custom variables in theme if you like
      // e.g. for date/time format or locale-based logic
      // Or store user roles, etc. in a sub-namespace
      custom: {
        locale,
        dateFormat: 'MM/dd/yyyy', // example global date format
        timeFormat: 'HH:mm',      // example global time format
      },
    });

    // 10) Make typography responsive
    newTheme = responsiveFontSizes(newTheme, { factor: 2.0 });

    // 11) Additional deep merges for smaller screen heading sizes
    newTheme = deepmerge(newTheme, {
      typography: {
        h1: {
          [newTheme.breakpoints.down('sm')]: {
            fontSize: '2rem',
          },
        },
        h2: {
          [newTheme.breakpoints.down('sm')]: {
            fontSize: '1.75rem',
          },
        },
        h3: {
          [newTheme.breakpoints.down('sm')]: {
            fontSize: '1.5rem',
          },
        },
        body1: {
          [newTheme.breakpoints.down('sm')]: {
            fontSize: `${baseFontSize - 2}px`,
          },
        },
      },
    });

    return newTheme;
  }, [
    mode,
    largeTextEnabled,
    baseFontSize,
    primaryColor,
    secondaryColor,
    successColor,
    warningColor,
    errorColor,
    infoColor,
    greyColor,
    forceWhiteBackground,
    locale // if your theme also depends on locale
  ]);

  return (
    <ThemeContext.Provider
      value={{
        mode,
        toggleTheme,
        largeTextEnabled,
        toggleLargeText,
        locale,
        setLocale
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProviderComponent;
