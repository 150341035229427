// useGuestInfo.js

import { useEffect, useState, useCallback } from 'react';

/**
 * Manages retrieving/saving guest email & name in localStorage
 * Also manages creation/retrieval of a guestId if no currentUser
 */
export default function useGuestInfo(currentUser) {
  const [guestId, setGuestId] = useState(null);
  const [guestEmail, setGuestEmail] = useState('');
  const [guestName, setGuestName] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [nameInput, setNameInput] = useState('');

  useEffect(() => {
    // If user is logged in, no need to handle guest
    if (currentUser?.uid) {
      setGuestId(null);
      setGuestEmail('');
      setGuestName('');
      setEmailInput('');
      setNameInput('');
      return;
    }

    // 1) Retrieve or create guestId
    let storedGuestId = localStorage.getItem('guestId');
    if (!storedGuestId) {
      storedGuestId = `guest_${Date.now()}`;
      localStorage.setItem('guestId', storedGuestId);
    }
    setGuestId(storedGuestId);

    // 2) Retrieve stored email & name
    const storedGuestEmail = localStorage.getItem('guestEmail');
    const storedGuestName = localStorage.getItem('guestName');

    if (storedGuestEmail) {
      setGuestEmail(storedGuestEmail);
      setEmailInput(storedGuestEmail);
    }
    if (storedGuestName) {
      setGuestName(storedGuestName);
      setNameInput(storedGuestName);
    }
  }, [currentUser?.uid]);

  // Save guest info to localStorage
  const handleSaveGuestInfo = useCallback(() => {
    const trimmedEmail = emailInput.trim();
    const trimmedName = nameInput.trim();

    if (!trimmedEmail) {
      return false; // indicates invalid input
    }
    localStorage.setItem('guestEmail', trimmedEmail);
    setGuestEmail(trimmedEmail);

    if (trimmedName) {
      localStorage.setItem('guestName', trimmedName);
      setGuestName(trimmedName);
    }

    return true; // success
  }, [emailInput, nameInput]);

  return {
    guestId,
    guestEmail,
    guestName,
    emailInput,
    nameInput,
    setEmailInput,
    setNameInput,
    handleSaveGuestInfo,
  };
}
