// src/services/events.js
import { db } from '../firebase';
import {
  collection,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
  Timestamp
} from 'firebase/firestore';

// Reference to the "events" collection in Firestore
const eventsRef = collection(db, 'calendar'); 
// ^ If your collection is actually named "calendar" (as in your existing code),
//   you might prefer to keep it that way. Or rename to "events" if you prefer.

export async function getAllEvents() {
  const snapshot = await getDocs(eventsRef);
  return snapshot.docs.map((docSnap) => ({
    id: docSnap.id,
    ...docSnap.data(),
  }));
}

export async function addEvent(eventData) {
  const newEvent = {
    ...eventData,
    createdAt: Timestamp.now(),
  };
  const docRef = await addDoc(eventsRef, newEvent);
  return docRef.id;
}

export async function updateEvent(eventId, updateData) {
  const eventDoc = doc(db, 'calendar', eventId);
  // ^ Again, if your collection name is "calendar," reference it exactly.
  //   If your collection is "events," then you'd do doc(db, 'events', eventId).

  await updateDoc(eventDoc, {
    ...updateData,
    updatedAt: Timestamp.now(),
  });
}

export async function deleteEvent(eventId) {
  const eventDoc = doc(db, 'calendar', eventId);
  await deleteDoc(eventDoc);
}
