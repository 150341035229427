import React, { useContext, useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  Tooltip,
  Box,
  Chip,
  useMediaQuery,
  Skeleton,
  styled,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { signOut } from 'firebase/auth';

// Icons
import {
  Menu as MenuIcon,
} from '@mui/icons-material';

// Context & Services
import { AuthContext } from '../../../context/AuthContext';
import { CRMContext } from '../../../context/CRMContext';
import { SubscriptionContext } from '../../../context/SubscriptionContext';
import { auth } from '../../../firebase';

// Example subcomponents (adjust paths as needed)
import CommerceNavBar from '../../MultiSectionSubNav';
import WorkMenu from './WorkMenu';
import ProfileMenu from './ProfileMenu';
import HelpMenu from './HelpMenu';
import MobileMenu from '../Mobile/MobileMenu.jsx';
import DesktopLoggedInNavItems from '../Desktop/DesktopLoggedInNavItems.jsx';
import DesktopLoggedInRightItems from '../Desktop/DesktopLoggedInRightItems.jsx';
import MobileLoggedInView from '../Mobile/MobileLoggedInView.jsx';
import DesktopLoggedOutNavItems from '../Desktop/DesktopLoggedOutNavItems.jsx';
import DesktopLoggedOutRightItems from '../Desktop/DesktopLoggedOutRightItems.jsx';
import MobileLoggedOutView from '../Mobile/MobileLoggedOutView.jsx';

const GRADIENT_BG = 'linear-gradient(90deg, #212121, #424242)';

export const buttonStyles = {
  textTransform: 'none',
  fontWeight: 'bold',
  borderRadius: '20px',
  px: 2,
  color: '#ffffff',
  boxShadow: 'none',
  backgroundColor: 'rgba(255,255,255,0.2)',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: 'rgba(255,255,255,0.4)',
  },
};

export const iconButtonStyles = {
  color: '#fff',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: 'rgba(255,255,255,0.15)',
  },
};

export const avatarStyles = {
  bgcolor: 'rgba(255,255,255,0.3)',
  color: '#fff',
  fontWeight: 'bold',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    bgcolor: 'rgba(255,255,255,0.5)',
  },
};

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  background: GRADIENT_BG,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  display: 'flex',
  justifyContent: 'center',
  borderRadius: 0,
  boxShadow: '0px 2px 10px rgba(0,0,0,0.5)',
}));

function getRecommendedArticle(pathname) {
  // Basic placeholder logic
  return "We don't have anything to show just yet.";
}

const Navbar = ({ logo }) => {
  const { currentUser } = useContext(AuthContext);
  const { tasks = [] } = useContext(CRMContext);

  // Pull currentPlan (e.g. "Starter"), planLoading, and trialDaysRemaining from SubscriptionContext
  const { currentPlan, planLoading, trialDaysRemaining } = useContext(SubscriptionContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const isCommerceSection = location.pathname.startsWith('/commerce/');

  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [workAnchorEl, setWorkAnchorEl] = useState(null);
  const [mobileAnchorEl, setMobileAnchorEl] = useState(null);
  const [helpAnchorEl, setHelpAnchorEl] = useState(null);

  // Submenus
  const [salesAnchorEl, setSalesAnchorEl] = useState(null);
  const [marketingAnchorEl, setMarketingAnchorEl] = useState(null);
  const [serviceAnchorEl, setServiceAnchorEl] = useState(null);
  const [contentAnchorEl, setContentAnchorEl] = useState(null);
  const [operationsAnchorEl, setOperationsAnchorEl] = useState(null);
  const [commerceAnchorEl, setCommerceAnchorEl] = useState(null);

  const handleSignOut = useCallback(async () => {
    await signOut(auth);
  }, []);

  // Profile
  const handleProfileMenuOpen = (e) => setProfileAnchorEl(e.currentTarget);
  const handleProfileMenuClose = () => setProfileAnchorEl(null);

  // Work
  const handleWorkMenuOpen = (e) => setWorkAnchorEl(e.currentTarget);
  const handleWorkMenuClose = () => setWorkAnchorEl(null);

  // Mobile
  const handleMobileMenuOpen = (e) => setMobileAnchorEl(e.currentTarget);
  const handleMobileMenuClose = () => setMobileAnchorEl(null);

  // Help
  const handleHelpMenuOpen = (e) => setHelpAnchorEl(e.currentTarget);
  const handleHelpMenuClose = () => setHelpAnchorEl(null);

  // Sales
  const handleSalesMenuOpen = (e) => setSalesAnchorEl(e.currentTarget);
  const handleSalesMenuClose = () => setSalesAnchorEl(null);

  // Marketing
  const handleMarketingMenuOpen = (e) => setMarketingAnchorEl(e.currentTarget);
  const handleMarketingMenuClose = () => setMarketingAnchorEl(null);

  // Service
  const handleServiceMenuOpen = (e) => setServiceAnchorEl(e.currentTarget);
  const handleServiceMenuClose = () => setServiceAnchorEl(null);

  // Content
  const handleContentMenuOpen = (e) => setContentAnchorEl(e.currentTarget);
  const handleContentMenuClose = () => setContentAnchorEl(null);

  // Operations
  const handleOperationsMenuOpen = (e) => setOperationsAnchorEl(e.currentTarget);
  const handleOperationsMenuClose = () => setOperationsAnchorEl(null);

  // Commerce
  const handleCommerceMenuOpen = (e) => setCommerceAnchorEl(e.currentTarget);
  const handleCommerceMenuClose = () => setCommerceAnchorEl(null);

  // Derive an initial for the user avatar
  const userInitial = useMemo(() => {
    if (!currentUser) return 'U';
    return (currentUser.displayName?.[0] || currentUser.email?.[0] || 'U').toUpperCase();
  }, [currentUser]);

  const userNameOrEmail = currentUser?.displayName || currentUser?.email || 'User';
  const userEmail = currentUser?.email;

  // How many tasks are incomplete
  const incompleteTasksCount = tasks.filter((t) => !t.completed).length;

  // Build the plan label chip
  let planLabel;
  if (planLoading) {
    planLabel = <Skeleton variant="text" width={80} />;
  } else {
    // If we do NOT have a currentPlan but do have trialDaysRemaining, show a "Trial" or "No Plan"
    if (!currentPlan) {
      if (trialDaysRemaining > 0) {
        planLabel = (
          <Chip
            label={`Trial (${trialDaysRemaining} days left)`}
            color="info"
            variant="outlined"
            sx={{
              mr: 2,
              fontWeight: 'bold',
              borderRadius: '10px',
              color: '#fff',
              borderColor: 'rgba(255,255,255,0.6)',
            }}
          />
        );
      } else {
        planLabel = (
          <Chip
            label="No Plan"
            color="default"
            variant="outlined"
            sx={{
              mr: 2,
              fontWeight: 'bold',
              borderRadius: '10px',
              color: '#fff',
              borderColor: 'rgba(255,255,255,0.6)',
            }}
          />
        );
      }
    } else {
      // We have a plan name => display that
      planLabel = (
        <Chip
          label={currentPlan}
          color="success"
          variant="outlined"
          sx={{
            mr: 2,
            fontWeight: 'bold',
            borderRadius: '10px',
            color: '#fff',
            borderColor: 'rgba(255,255,255,0.6)',
          }}
        />
      );
    }
  }

  // Logged-in desktop nav
  const desktopLoggedInNavItems = (
    <DesktopLoggedInNavItems
      buttonStyles={buttonStyles}
      handleSalesMenuOpen={handleSalesMenuOpen}
      salesAnchorEl={salesAnchorEl}
      handleSalesMenuClose={handleSalesMenuClose}
      handleMarketingMenuOpen={handleMarketingMenuOpen}
      marketingAnchorEl={marketingAnchorEl}
      handleMarketingMenuClose={handleMarketingMenuClose}
      handleServiceMenuOpen={handleServiceMenuOpen}
      serviceAnchorEl={serviceAnchorEl}
      handleServiceMenuClose={handleServiceMenuClose}
      handleContentMenuOpen={handleContentMenuOpen}
      contentAnchorEl={contentAnchorEl}
      handleContentMenuClose={handleContentMenuClose}
      handleOperationsMenuOpen={handleOperationsMenuOpen}
      operationsAnchorEl={operationsAnchorEl}
      handleOperationsMenuClose={handleOperationsMenuClose}
      handleCommerceMenuOpen={handleCommerceMenuOpen}
      commerceAnchorEl={commerceAnchorEl}
      handleCommerceMenuClose={handleCommerceMenuClose}
    />
  );

  // Logged-in right items
  const desktopLoggedInRightItems = (
    <DesktopLoggedInRightItems
      planLabel={planLabel}
      buttonStyles={buttonStyles}
      iconButtonStyles={iconButtonStyles}
      handleHelpMenuOpen={handleHelpMenuOpen}
      helpAnchorEl={helpAnchorEl}
      handleTasksMenuOpen={handleWorkMenuOpen}
      tasksAnchorEl={workAnchorEl}
      incompleteTasksCount={incompleteTasksCount}
      handleProfileMenuOpen={handleProfileMenuOpen}
      profileAnchorEl={profileAnchorEl}
      avatarStyles={avatarStyles}
      userInitial={userInitial}
      userNameOrEmail={userNameOrEmail}
    />
  );

  // Logged-in mobile
  const mobileLoggedInView = (
    <MobileLoggedInView
      iconButtonStyles={iconButtonStyles}
      helpAnchorEl={helpAnchorEl}
      handleHelpMenuOpen={handleHelpMenuOpen}
      mobileAnchorEl={mobileAnchorEl}
      handleMobileMenuOpen={handleMobileMenuOpen}
    />
  );

  // Logged-out desktop nav
  const desktopLoggedOutNavItems = (
    <DesktopLoggedOutNavItems buttonStyles={buttonStyles} />
  );

  // Logged-out right items
  const desktopLoggedOutRightItems = (
    <DesktopLoggedOutRightItems
      buttonStyles={buttonStyles}
      iconButtonStyles={iconButtonStyles}
      helpAnchorEl={helpAnchorEl}
      handleHelpMenuOpen={handleHelpMenuOpen}
    />
  );

  // Logged-out mobile
  const mobileLoggedOutView = (
    <MobileLoggedOutView
      iconButtonStyles={iconButtonStyles}
      helpAnchorEl={helpAnchorEl}
      handleHelpMenuOpen={handleHelpMenuOpen}
      mobileAnchorEl={mobileAnchorEl}
      handleMobileMenuOpen={handleMobileMenuOpen}
    />
  );

  return (
    <>
      <StyledAppBar position="fixed" elevation={4}>
        <Toolbar
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {/* LEFT: Logo + (Desktop Nav if not mobile) */}
          <Box display="flex" alignItems="center" gap={2}>
            <Link
              to="/"
              style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
            >
              {logo && (
                <Box
                  component="img"
                  src={logo}
                  alt="Logo"
                  sx={{ height: 22, width: 'auto', mr: 1 }}
                />
              )}
            </Link>

            {/* Conditionally render full navigation items (desktop) */}
            {currentUser && !isMobile && desktopLoggedInNavItems}
            {!currentUser && !isMobile && desktopLoggedOutNavItems}
          </Box>

          {/* RIGHT: Either logged in or logged out */}
          <Box display="flex" alignItems="center" gap={2}>
            {currentUser
              ? isMobile
                ? mobileLoggedInView
                : desktopLoggedInRightItems
              : isMobile
              ? mobileLoggedOutView
              : desktopLoggedOutRightItems}
          </Box>
        </Toolbar>

        {/* Mobile Menu */}
        <MobileMenu
          anchorEl={mobileAnchorEl}
          open={Boolean(mobileAnchorEl)}
          onClose={handleMobileMenuClose}
          currentUser={currentUser}
          userNameOrEmail={userNameOrEmail}
          userEmail={userEmail}
          handleSignOut={handleSignOut}
          handleTasksMenuOpen={handleWorkMenuOpen}
          tasksAnchorEl={workAnchorEl}
        />

        {/* Profile Menu (only if logged in) */}
        {currentUser && (
          <ProfileMenu
            anchorEl={profileAnchorEl}
            open={Boolean(profileAnchorEl)}
            onClose={handleProfileMenuClose}
            userNameOrEmail={userNameOrEmail}
            userEmail={userEmail}
            currentPlan={currentPlan} // Now we can show plan details in ProfileMenu if desired
            planLoading={planLoading}
            trialDaysRemaining={trialDaysRemaining}
            handleSignOut={handleSignOut}
          />
        )}

        {/* Work (Tasks) Menu */}
        <WorkMenu
          anchorEl={workAnchorEl}
          open={Boolean(workAnchorEl)}
          onClose={handleWorkMenuClose}
        />

        {/* Help Menu */}
        <HelpMenu
          anchorEl={helpAnchorEl}
          open={Boolean(helpAnchorEl)}
          onClose={handleHelpMenuClose}
          location={location}
          getRecommendedArticle={getRecommendedArticle}
        />
      </StyledAppBar>

      {/* If user is in /commerce path, render CommerceNavBar below main Nav */}
      {isCommerceSection && <CommerceNavBar />}
    </>
  );
};

Navbar.propTypes = {
  logo: PropTypes.string,
};

export default React.memo(Navbar);
