// ChatInputBox.js

import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Box, TextField, Button } from '@mui/material';

/**
 * A reusable chat input box for composing and sending messages.
 */
export default function ChatInputBox({
  newMessage,
  setNewMessage,
  currentUser,
  guestEmail,
  isConversationClosed,
  handleSendMessage,
  placeholder = 'Type your message...',
  disabledPlaceholder = 'Enter email above first...',
}) {
  const isDisabled = (!currentUser?.uid && !guestEmail) || isConversationClosed;
  const activePlaceholder = isDisabled ? disabledPlaceholder : placeholder;

  const handleEnterPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        handleSendMessage();
      }
    },
    [handleSendMessage]
  );

  // Alternatively, a form onSubmit:
  const onSubmit = (e) => {
    e.preventDefault();
    handleSendMessage();
  };

  return (
    <Box component="form" onSubmit={onSubmit} sx={{ display: 'flex', gap: 1, px: 1 }}>
      <TextField
        size="small"
        fullWidth
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
        placeholder={activePlaceholder}
        disabled={isDisabled}
        onKeyDown={handleEnterPress}
        aria-label="Chat message input field"
      />
      <Button
        variant="contained"
        type="submit"
        disabled={isDisabled}
        sx={{ whiteSpace: 'nowrap' }}
      >
        Send
      </Button>
    </Box>
  );
}

ChatInputBox.propTypes = {
  newMessage: PropTypes.string.isRequired,
  setNewMessage: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  guestEmail: PropTypes.string,
  isConversationClosed: PropTypes.bool,
  handleSendMessage: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  disabledPlaceholder: PropTypes.string,
};
