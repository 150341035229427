// File: src/routes/moduleProtectedRoutes.js

import React, { lazy } from 'react';
// Route guard that checks user has required module in userProfile.selectedModules
import ModuleRoute from '../components/ModuleRoute';

// ===== COMMERCE Lazy Pages =====
const CatalogItemsList = lazy(() =>
  import('../pages/Commerce/CatalogItems/CatalogList/CatalogItemsList')
);
const CatalogItemDetails = lazy(() =>
  import('../pages/Commerce/CatalogItems/CatalogDetails/CatalogItemDetails')
);
const Quotes = lazy(() => import('../pages/Commerce/Quotes/QuotesList/Quotes'));
const QuoteDetails = lazy(() =>
  import('../pages/Commerce/Quotes/QuoteDetails/QuoteDetailsContainer.jsx')
);
const Invoices = lazy(() =>
  import('../pages/Commerce/Invoices/InvoiceList/Invoices.jsx')
);
const InvoiceDetails = lazy(() =>
  import('../pages/Commerce/Invoices/InvoiceDetails/InvoiceDetailsContainer.jsx')
);
const PaymentLinks = lazy(() =>
  import('../pages/Commerce/PaymentLinks/PaymentList/PaymentLinksPage.jsx')
);
const Subscriptions = lazy(() =>
  import('../pages/Commerce/Subscriptions/SubscriptionList/SubscriptionsPage.jsx')
);

// ===== SALES Lazy Pages =====
const DealsList = lazy(() => import('/Users/willradley/orthinas-crm/src/pages/Sales/Deals/DealsList/DealsList.js'));
const DealDetails = lazy(() => import('/Users/willradley/orthinas-crm/src/pages/Sales/Deals/DealDetails/DealDetails.js'));

// ===== MARKETING Lazy Pages =====
const MarketingPage = lazy(() => import('../pages/Marketing/MarketingPage'));
const MarketingCampaignDetails = lazy(() =>
  import('../pages/Marketing/MarketingItemDetails')
);
const Forms = lazy(() => import('../pages/Marketing/Forms.jsx'));

// ===== SERVICE Lazy Pages =====
const Cases = lazy(() => import('../pages/Service/Cases/CasesPage.js'));
const CaseDetails = lazy(() => import('../pages/Service/Cases/CaseDetails.js'));
const Knowledge = lazy(() => import('../pages/Service/Knowledge.jsx'));

// ===== OPERATIONS Lazy Pages =====
const Automations = lazy(() => import('../pages/Operations/Automations.jsx'));
const DataSync = lazy(() => import('../pages/Operations/DataSync.jsx'));
const DataQuality = lazy(() =>
  import('../pages/Operations/DataQualityAutomation.jsx')
);

// ===== CONTENT Lazy Pages =====
const LandingPages = lazy(() => import('../pages/Content/LandingPages.jsx'));
const VideoPodcasts = lazy(() => import('../pages/Content/VideoPodcast.jsx'));
const SEO = lazy(() => import('../pages/Content/SEO.jsx'));

/**
 * Each object in moduleProtectedRoutes:
 *  - path: the route path
 *  - element: a <ModuleRoute requiredModule="XYZ"> wrapper around the page
 *
 * Later in App.js (or a separate router file), you'll nest these under <ProtectedRoute>.
 */
export const moduleProtectedRoutes = [
  // ====================== COMMERCE ======================
  {
    path: '/commerce/catalog',
    element: (
      <ModuleRoute requiredModule="Commerce">
        <CatalogItemsList />
      </ModuleRoute>
    ),
  },
  {
    path: '/commerce/catalog/:id',
    element: (
      <ModuleRoute requiredModule="Commerce">
        <CatalogItemDetails />
      </ModuleRoute>
    ),
  },
  {
    path: '/commerce/quotes',
    element: (
      <ModuleRoute requiredModule="Commerce">
        <Quotes />
      </ModuleRoute>
    ),
  },
  {
    path: '/quotes/:id',
    element: (
      <ModuleRoute requiredModule="Commerce">
        <QuoteDetails />
      </ModuleRoute>
    ),
  },
  {
    path: '/commerce/invoices',
    element: (
      <ModuleRoute requiredModule="Commerce">
        <Invoices />
      </ModuleRoute>
    ),
  },
  {
    path: '/commerce/invoices/:id',
    element: (
      <ModuleRoute requiredModule="Commerce">
        <InvoiceDetails />
      </ModuleRoute>
    ),
  },
  {
    path: '/commerce/paymentlinks',
    element: (
      <ModuleRoute requiredModule="Commerce">
        <PaymentLinks />
      </ModuleRoute>
    ),
  },
  {
    path: '/commerce/subscriptions',
    element: (
      <ModuleRoute requiredModule="Commerce">
        <Subscriptions />
      </ModuleRoute>
    ),
  },

  // ====================== SALES ======================
  {
    path: '/sales/deals',
    element: (
      <ModuleRoute requiredModule="Sales">
        <DealsList />
      </ModuleRoute>
    ),
  },
  {
    path: '/deal/:id',
    element: (
      <ModuleRoute requiredModule="Sales">
        <DealDetails />
      </ModuleRoute>
    ),
  },

  // ====================== MARKETING ======================
  {
    path: '/marketing/campaigns',
    element: (
      <ModuleRoute requiredModule="Marketing">
        <MarketingPage />
      </ModuleRoute>
    ),
  },
  {
    path: '/marketing/:id',
    element: (
      <ModuleRoute requiredModule="Marketing">
        <MarketingCampaignDetails />
      </ModuleRoute>
    ),
  },
  {
    path: '/forms',
    element: (
      <ModuleRoute requiredModule="Marketing">
        <Forms />
      </ModuleRoute>
    ),
  },

  // ====================== SERVICE ======================
  {
    path: '/service/cases',
    element: (
      <ModuleRoute requiredModule="Service">
        <Cases />
      </ModuleRoute>
    ),
  },
  {
    path: '/cases/:id',
    element: (
      <ModuleRoute requiredModule="Service">
        <CaseDetails />
      </ModuleRoute>
    ),
  },
  {
    path: '/service/knowledge',
    element: (
      <ModuleRoute requiredModule="Service">
        <Knowledge />
      </ModuleRoute>
    ),
  },

  // ====================== OPERATIONS ======================
  {
    path: '/automations',
    element: (
      <ModuleRoute requiredModule="Operations">
        <Automations />
      </ModuleRoute>
    ),
  },
  {
    path: '/datasync',
    element: (
      <ModuleRoute requiredModule="Operations">
        <DataSync />
      </ModuleRoute>
    ),
  },
  {
    path: '/dataquality',
    element: (
      <ModuleRoute requiredModule="Operations">
        <DataQuality />
      </ModuleRoute>
    ),
  },

  // ====================== CONTENT ======================
  {
    path: '/content/landingpages',
    element: (
      <ModuleRoute requiredModule="Content">
        <LandingPages />
      </ModuleRoute>
    ),
  },
  {
    path: '/content/videopodcasts',
    element: (
      <ModuleRoute requiredModule="Content">
        <VideoPodcasts />
      </ModuleRoute>
    ),
  },
  {
    path: '/content/seo',
    element: (
      <ModuleRoute requiredModule="Content">
        <SEO />
      </ModuleRoute>
    ),
  },
];
