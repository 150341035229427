import {
    collection,
    query,
    where,
    getDocs,
    addDoc,
    serverTimestamp,
    doc,
    updateDoc,
    deleteDoc,
    getDoc,
  } from 'firebase/firestore';
  import { db } from '../firebase';
  
  /**
   * Fetch all knowledge articles for a specific user.
   * @param {string} userId
   * @returns {Promise<Array>} Array of article docs
   */
  export async function getAllKnowledgeArticles(userId) {
    if (!userId) return [];
    const colRef = collection(db, 'userSelfHelpArticles');
    const q = query(colRef, where('userId', '==', userId));
    
    const snapshot = await getDocs(q);
    const articles = [];
    snapshot.forEach(docSnap => {
      articles.push({
        id: docSnap.id,
        ...docSnap.data()
      });
    });
    return articles;
  }

  
/** Update (edit) an existing knowledge article by doc ID. */
export async function editKnowledgeArticle(articleId, updatedFields) {
    if (!articleId) {
      throw new Error('No articleId provided. Cannot edit article.');
    }
    const docRef = doc(db, 'userSelfHelpArticles', articleId);
    await updateDoc(docRef, updatedFields);
  }
  
  /** Delete an existing knowledge article by doc ID. */
  export async function deleteKnowledgeArticle(articleId) {
    if (!articleId) {
      throw new Error('No articleId provided. Cannot delete article.');
    }
    const docRef = doc(db, 'userSelfHelpArticles', articleId);
    await deleteDoc(docRef);
  }
  
  /**
   * Add a new knowledge article to Firestore.
   * @param {Object} article  - Must contain { userId, title, category, status, content? }
   * @returns {Promise<string>} the newly created doc ID
   */
  export async function addKnowledgeArticle(article) {
    if (!article?.userId) {
      throw new Error('No userId provided. Cannot add article.');
    }
    const colRef = collection(db, 'userSelfHelpArticles');
    const docRef = await addDoc(colRef, {
      ...article,
      createdAt: serverTimestamp(), // or store ISO string if you prefer
    });
    return docRef.id;
  }
  
  export async function getAllKnowledgeArticlesForPortal(userId) {
    if (!userId) return [];
    const colRef = collection(db, 'knowledgeArticles');
    const q = query(colRef, where('userId', '==', userId), where('status', '==', 'Published'));
    const snapshot = await getDocs(q);
  
    const articles = [];
    snapshot.forEach(docSnap => {
      articles.push({
        id: docSnap.id,
        ...docSnap.data()
      });
    });
    return articles;
  }

  export async function getPublishedArticlesByUserId(userId) {
    const articlesRef = collection(db, 'userSelfHelpArticles');
    const q = query(
      articlesRef,
      where('userId', '==', userId),
      where('status', '==', 'Published')
    );
    const snapshot = await getDocs(q);
    return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  }

  export async function getPortalById(portalId) {
    const portalRef = doc(db, 'selfHelpPortals', portalId);
    const snapshot = await getDoc(portalRef);
    if (!snapshot.exists()) {
      return null;
    }
    return { id: snapshot.id, ...snapshot.data() };
  }