// src/components/ProtectedRoute.js
import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { db } from '../firebase'; // Adjust path to your firebase config
import { doc, onSnapshot } from 'firebase/firestore';

// Simple loading spinner (replace with your own if desired)
function LoadingSpinner() {
  return <div style={{ textAlign: 'center', marginTop: '2rem' }}>Loading...</div>;
}

const ProtectedRoute = ({ children }) => {
  const { currentUser } = useContext(AuthContext);

  // We store staffApproval as either true, false, or null. 
  // null will mean "still loading" or "undefined" from Firestore.
  const [staffApproval, setStaffApproval] = useState(null);
  const [loadingProfile, setLoadingProfile] = useState(true);

  useEffect(() => {
    // If user is not logged in, no need to fetch Firestore.
    if (!currentUser) {
      setLoadingProfile(false);
      return;
    }

    // Subscribe in real-time to the user's doc in Firestore
    const unsubscribe = onSnapshot(doc(db, 'users', currentUser.uid), (docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        // If staffApproval is undefined (old user doc) we treat them as "approved" automatically
        setStaffApproval(
          typeof data.staffApproval === 'boolean' ? data.staffApproval : true
        );
      } else {
        // If doc doesn't exist at all, also treat as "approved" (old user with no doc)
        setStaffApproval(true);
      }
      setLoadingProfile(false);
    });

    return () => unsubscribe();
  }, [currentUser]);

  if (loadingProfile) {
    return <LoadingSpinner />;
  }

  // 1) If not signed in, redirect to /signin
  if (!currentUser) {
    return <Navigate to="/signin" replace />;
  }

  // 2) If staffApproval is explicitly false => user is not yet approved
  if (staffApproval === false) {
    return <Navigate to="/onboarding" replace />;
  }

  // 3) Otherwise (staffApproval === true OR undefined doc => "grandfather" them in)
  return <>{children}</>;
};

export default ProtectedRoute;
